import * as z from 'zod';

import { FIXED_COUNTRY } from '@/constants/misc';
import { COUNTRY_LIST } from '@/constants/us-countries';
import { US_STATES } from '@/constants/us-states';
import { PO_BOX } from '@/constants/regexps';

export const baseAddressFormSchema = z.object({
  'postal-code': z.string().length(5, 'Postal Code is not valid'),
  'street-address': z
    .string()
    .regex(PO_BOX, 'PO Box address is not accepted')
    .min(1, 'Street Address is required'),
  'unit-number': z.string().nullable(),
  city: z.string().min(1, 'City is required'),
  country: z
    .enum(COUNTRY_LIST, {
      errorMap: () => {
        return { message: 'Please select a Country' };
      },
    })
    .default(FIXED_COUNTRY),
  state: z.enum(US_STATES, {
    errorMap: () => {
      return { message: 'Please select a State' };
    },
  }),
});

export type AddressFormSchema = z.infer<typeof baseAddressFormSchema>;
