import getBuyerLinkToken from '@/api/hooks/useGetBuyerPlaidLinkToken/getBuyerLinkToken';
import { get, isEmpty } from 'lodash';
import {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  Dispatch,
  createContext,
} from 'react';
import useToken from './UserAuth';

interface LinkProviderProps {
  children: React.ReactNode;
}

type LinkToken = Record<string, string>;

interface LinkState {
  byUser: LinkToken;
  byItem: LinkToken;
}

const initialState = {
  byUser: {},
  byItem: {},
};
type LinkAction =
  | {
      type: 'LINK_TOKEN_CREATED';
      id: string;
      token: string;
    }
  | { type: 'LINK_TOKEN_UPDATE_MODE_CREATED'; id: string; token: string }
  | { type: 'DELETE_USER_LINK_TOKEN'; id: string }
  | { type: 'DELETE_ITEM_LINK_TOKEN'; id: string };

interface LinkContextShape extends LinkState {
  dispatch: Dispatch<LinkAction>;
  generateLinkToken: (userid: string, institution?: string) => void;
  deleteLinkToken: (userid?: string, institution?: string) => void;
  linkTokens: LinkState;
}
const LinkContext = createContext<LinkContextShape>(
  initialState as LinkContextShape,
);

export const LinkProvider = (props: LinkProviderProps): JSX.Element => {
  const [linkTokens, dispatch] = useReducer(reducer, initialState);
  const { currentToken } = useToken();

  const generateLinkToken = useCallback(
    async (userId: string, institution?: string) => {
      const linkTokenResponse = await getBuyerLinkToken({
        query: institution,
        token: currentToken,
      });
      const token = get(linkTokenResponse, 'linkTokens', '');

      if (token) {
        if (institution != null) {
          dispatch({
            type: 'LINK_TOKEN_UPDATE_MODE_CREATED',
            id: institution,
            token,
          });
        } else {
          dispatch({ type: 'LINK_TOKEN_CREATED', id: userId, token });
        }
      } else {
        console.log('error', linkTokenResponse);
      }
    },
    [currentToken],
  );

  const deleteLinkToken = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (userId?: string, institution?: string): Promise<void> => {
      if (userId != null) {
        dispatch({
          type: 'DELETE_USER_LINK_TOKEN',
          id: userId,
        });
      } else {
        dispatch({
          type: 'DELETE_ITEM_LINK_TOKEN',
          id: institution ?? '',
        });
      }
    },
    [],
  );

  const value = useMemo(
    () => ({
      generateLinkToken,
      deleteLinkToken,
      byUser: linkTokens.byUser,
      byItem: linkTokens.byItem,
      linkTokens,
      dispatch,
    }),
    [linkTokens, generateLinkToken, deleteLinkToken],
  );

  return <LinkContext.Provider value={value} {...(props as object)} />;
};

const reducer = (state: LinkState, action: LinkAction): LinkState => {
  switch (action.type) {
    case 'LINK_TOKEN_CREATED':
      return {
        ...state,
        byUser: {
          [action.id]: action.token,
        },
      };

    case 'LINK_TOKEN_UPDATE_MODE_CREATED':
      return {
        ...state,

        byItem: {
          ...get(state, 'byItem', {}),
          [action.id]: action.token,
        },
      };
    case 'DELETE_USER_LINK_TOKEN':
      return {
        ...state,
        byUser: {
          [action.id]: '',
        },
      };
    case 'DELETE_ITEM_LINK_TOKEN':
      return {
        ...state,
        byItem: {
          ...get(state, 'byItem', {}),
          [action.id]: '',
        },
      };
    default:
      console.warn('unknown action');
      return state;
  }
};

export const useLink = (): LinkContextShape => {
  const context = useContext<LinkContextShape>(LinkContext);
  if (isEmpty(context)) {
    throw new Error(`useLink must be used within a LinkProvider`);
  }

  return context;
};
export default useLink;
