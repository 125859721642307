import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import { ICONS } from '@/constants/icons';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import type { EnumSystemRoleType } from '@/types/system-roles';
import { Key } from 'react';
export { EnumSystemRoleType as SystemRoleType } from '@/types/system-roles';

export enum SystemUsersRole {
  ADMINISTRATOR = 'Administrator',
  OPERATING = 'Operating',
  ACCOUNTING = 'Accounting',
}

export enum SystemRelationshipType {
  BENEFICIAL_OWNER_OF = 'BENEFICIAL_OWNER_OF',
  MANAGING_PERSON_OF = 'MANAGING_PERSON_OF',
}

export enum SystemUsersStatuses {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DEACTIVATED = 'DEACTIVATED',
}

export enum SystemUsersStatusValue {
  ACTIVE = 'Active',
  PENDING = 'Pending',
  DEACTIVATED = 'Deactivated',
}

export const CHIP_COLORS: Record<string, string> = {
  ACTIVE: '#e1f4ea',
  PENDING: '#FFF0C5',
  DEACTIVATED: '#F1F1F1',
};

export const columns: GridColDef[] = [
  {
    field: 'name',
    display: 'flex',
    headerAlign: 'left',
    headerName: 'Name',
    minWidth: 160,
    flex: 2,
    sortable: true,
  },
  {
    field: 'systemRoles',
    display: 'flex',
    headerAlign: 'left',
    headerName: 'Roles',
    minWidth: 160,
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      const roles = params.value?.map(
        (role: { type: Key | null | undefined }) => (
          <span style={{ display: 'block', margin: '2px 0' }} key={role.type}>
            {SystemUsersRole[role.type as EnumSystemRoleType]}
          </span>
        ),
      );

      return (
        <Typography
          variant="body2"
          sx={{
            padding: '10px 0',
          }}
        >
          {roles}
        </Typography>
      );
    },
  },
  {
    field: 'location',
    display: 'flex',
    headerAlign: 'left',
    headerName: 'Location',
    minWidth: 160,
    flex: 2,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      const locationVal = params.value ?? [];

      let location;
      if (locationVal === 'all') {
        location = 'All facilities';
      } else if (Array.isArray(locationVal)) {
        location = (locationVal ?? []).map((loc) => (
          <span style={{ display: 'block', margin: '2px 0' }} key={loc}>
            {loc}
          </span>
        ));
      }
      return (
        <Typography
          variant="body2"
          sx={{
            padding: '10px 0',
          }}
        >
          {location}
        </Typography>
      );
    },
  },
  {
    field: 'status',
    display: 'flex',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Status',
    sortable: false,
    minWidth: 121,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const userStatus = (params.value as SystemUsersStatuses) ?? '';
      const color = CHIP_COLORS[userStatus];
      return userStatus ? (
        <Chip
          label={SystemUsersStatusValue[userStatus]}
          sx={{
            backgroundColor: color,
          }}
        />
      ) : (
        ''
      );
    },
  },
  {
    field: 'manage',
    display: 'flex',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Manage',
    filterable: false,
    sortable: false,
    minWidth: 89,
    flex: 1,
    renderCell: (params: GridRenderCellParams<() => void>): JSX.Element => (
      <IconButton sx={{ color: 'primary.200' }} onClick={params.value}>
        <ICONS.MdKeyboardArrowRight />
      </IconButton>
    ),
  },
];
