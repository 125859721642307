import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import _omit from 'lodash/omit';

export interface QueryEvents {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
}

export const useQueryWithCallbacks = <Res, Error>(
  options: UseQueryOptions<Res, Error> & Partial<QueryEvents>,
) => {
  const previousDataRef = useRef<Res | undefined>(undefined);
  const previousErrorRef = useRef<Error | undefined>(undefined);
  const originalOptions = _omit(options, ['onSuccess', 'onError']);
  const result = useQuery(originalOptions);

  useEffect(() => {
    if (result.isSuccess && options?.onSuccess && originalOptions.enabled) {
      if (previousDataRef.current !== result.data) {
        options.onSuccess(result.data);
        previousDataRef.current = result.data;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isSuccess, result.data, originalOptions.enabled]);

  useEffect(() => {
    if (result.isError && options?.onError && originalOptions.enabled) {
      if (previousErrorRef.current !== result.error) {
        options.onError(result.error);
        previousErrorRef.current = result.error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.isError, result.error, originalOptions.enabled]);

  return result;
};
