import { UseQueryResult } from '@tanstack/react-query';
import { useQueryWithCallbacks } from '@/api/utils/useQueryWithCallbacks';
import getSystemRoles from '@/api/hooks/useGetSystemRoles/getSystemRoles';
import type { SystemRoles } from '@/types/system-roles';
import type { QueryOptionsRequestCallback } from '@/api/types';

const useGetSystemRoles = (
  queryOptions?: QueryOptionsRequestCallback<SystemRoles[]>,
): UseQueryResult<SystemRoles[], Error> => {
  return useQueryWithCallbacks<SystemRoles[], Error>({
    queryKey: ['getSystemRoles'],
    queryFn: async () => await getSystemRoles(),
    ...queryOptions,
  });
};

export default useGetSystemRoles;
