import type {
  ItemDto,
  SectionDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import {
  BANKING_ACTIVITY_SECTION_ID,
  BANKING_ACTIVITY_SECTION_TITLE,
} from '@pages/Facilities/Sections/BankingActivity/data';
import type { UseFormSetValue } from 'react-hook-form';
import { z } from 'zod';
import type { FormSchema } from './types';

export class PrimaryBusinessType {
  static readonly BUSINESS_1: PrimaryBusinessType = new PrimaryBusinessType(
    'BUSINESS_1',
    'Top 1 vendor',
    'business-name-1',
    'transaction-volume-1',
  );

  static readonly BUSINESS_2: PrimaryBusinessType = new PrimaryBusinessType(
    'BUSINESS_2',
    'Top 2 vendor',
    'business-name-2',
    'transaction-volume-2',
  );

  static readonly BUSINESS_3: PrimaryBusinessType = new PrimaryBusinessType(
    'BUSINESS_3',
    'Top 3 vendor',
    'business-name-3',
    'transaction-volume-3',
  );

  private constructor(
    private readonly key: string,
    public readonly label: string,
    public readonly businessId: string,
    public readonly volumeId: string,
  ) {}

  toString(): string {
    return this.key;
  }
}

export const formSchema = z.object({
  [PrimaryBusinessType.BUSINESS_1.businessId]: z
    .string()
    .min(1, 'Top 1 Business Name is not valid'),
  [PrimaryBusinessType.BUSINESS_1.volumeId]: z
    .string()
    .min(1, 'Monthly Transaction Volume is not valid'),
  [PrimaryBusinessType.BUSINESS_2.businessId]: z.string().nullish(),
  [PrimaryBusinessType.BUSINESS_2.volumeId]: z.string().nullish(),
  [PrimaryBusinessType.BUSINESS_3.businessId]: z.string().nullish(),
  [PrimaryBusinessType.BUSINESS_3.volumeId]: z.string().nullish(),
});

export const PRIMARY_BUSINESS_PAGE_ID = 'primary-business-page';

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const items: ItemDto[] = [];

  Object.values(PrimaryBusinessType).forEach((item: PrimaryBusinessType) => {
    if (
      businessData[item.businessId] != null &&
      businessData[item.volumeId] != null
    ) {
      items.push(
        {
          id: item.businessId,
          type: 'QUESTION',
          question: item.label + ' business name',
          answer: businessData[item.businessId] as string,
        },
        {
          id: item.volumeId,
          type: 'QUESTION',
          question: item.label + ' volume',
          answer: businessData[item.volumeId] as string,
        },
      );
    }
  });

  return {
    id: BANKING_ACTIVITY_SECTION_ID,
    title: BANKING_ACTIVITY_SECTION_TITLE,
    page: {
      id: PRIMARY_BUSINESS_PAGE_ID,
      title: 'Banking Activity Section - Primary Business',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setPrimaryBusinessValues(
  detailType: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  detailType.page.items.forEach((item) => {
    setValues(item.id, item.answer);
  });
}
