import fetcher from '@/utils/fetcher';
import type { SystemRoles } from '@/types/system-roles';

const getSystemRoles = async (): Promise<SystemRoles[]> =>
  // eslint-disable-next-line @typescript-eslint/return-await
  fetcher<SystemRoles[]>({
    endpoint: `/system-roles/list`,
  });

export default getSystemRoles;
