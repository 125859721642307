import {
  PARSED_BOOLEAN_TUPPLE_LABELS,
  PARSED_BOOLEAN_TUPPLE_VALUES,
} from '@/constants/misc';
import { getRadioOptionList } from '@/utils/get-radio-option-list';
import type {
  FormSchema,
  withTransportation,
} from '@pages/Facilities/Sections/Details/Transportation/schema';
import type {
  ItemDto,
  SectionDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import {
  BUSINESS_DETAILS_SECTION_ID,
  BUSINESS_DETAILS_SECTION_TITLE,
} from '@pages/Facilities/Sections/Details/types';
import type { UseFormSetValue } from 'react-hook-form';
import type * as z from 'zod';

export const TRANSPORTATION_PAGE_ID = 'transportation-page';

export const optionList = getRadioOptionList({
  labels: PARSED_BOOLEAN_TUPPLE_LABELS,
  values: PARSED_BOOLEAN_TUPPLE_VALUES,
});

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const {
    'your-business-involve-transportation-or-delivery': involveTransportation,
  } = businessData;

  const items: ItemDto[] = [
    {
      id: 'your-business-involve-transportation-or-delivery',
      type: 'QUESTION',
      question:
        'Does your business involve transportation or delivery of marijuana between businesses?',
      answer: involveTransportation === 'true' ? 'Yes' : 'No',
    },
  ];

  if (involveTransportation === 'true') {
    const { 'name-location': nameLocation, 'license-number': licenseNumber } =
      businessData;
    items.push({
      id: 'name-location',
      type: 'QUESTION',
      question: 'Name of location',
      answer: nameLocation,
    });
    items.push({
      id: 'license-number',
      type: 'QUESTION',
      question: 'License Number',
      answer: licenseNumber,
    });
  }

  return {
    id: BUSINESS_DETAILS_SECTION_ID,
    title: BUSINESS_DETAILS_SECTION_TITLE,
    page: {
      id: TRANSPORTATION_PAGE_ID,
      title: 'Business Detail Section - Transportation',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setTransportationValues(
  detailType: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  detailType.page.items.forEach((item) => {
    if (item.id === 'your-business-involve-transportation-or-delivery') {
      setValues(item.id, item.answer === 'Yes' ? 'true' : 'false');
    } else {
      setValues(
        item.id as keyof z.infer<typeof withTransportation>,
        item.answer,
      );
    }
  });
}
