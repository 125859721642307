import type {
  ItemDto,
  SectionDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import {
  PARSED_BOOLEAN_TUPPLE_LABELS,
  PARSED_BOOLEAN_TUPPLE_VALUES,
} from '@/constants/misc';
import { US_STATES } from '@/constants/us-states';
import { getRadioOptionList } from '@/utils/get-radio-option-list';
import {
  BUSINESS_ACTIVITY_SECTION_ID,
  BUSINESS_ACTIVITY_SECTION_TITLE,
} from '@pages/Facilities/Sections/BusinessActivity/types';
import { nanoid } from 'nanoid';
import type { UseFormSetValue } from 'react-hook-form';
import type { FormSchema } from './schema';

export const optionList = getRadioOptionList({
  labels: PARSED_BOOLEAN_TUPPLE_LABELS,
  values: PARSED_BOOLEAN_TUPPLE_VALUES,
});

export const usStatesList = US_STATES.map((usState) => ({
  id: nanoid(),
  label: usState,
  value: usState,
}));

export const ACCEPT_OR_TRANSMIT_WIRE_TRANSFER_PAGE_ID =
  'accept-or-transmit-wire-transfer-page';

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const items: ItemDto[] = [];

  const {
    'your-business-accept-or-transmit-wire-transfers': acceptWire,
    'states-of-origin': statesOfOrigin,
    'nature-of-business': natureOfBusiness,
  } = businessData;

  let statesOfOriginString = '';

  statesOfOrigin?.forEach((stateOfOrigin, index) => {
    statesOfOriginString =
      statesOfOriginString + (index === 0 ? '' : ',') + stateOfOrigin;
  });

  items.push({
    id: 'your-business-accept-or-transmit-wire-transfers',
    type: 'QUESTION',
    question: 'Does your business accept or transmit wire transfers?',
    answer: acceptWire === 'false' ? 'No' : 'Yes',
  });

  if (acceptWire === 'true') {
    items.push({
      id: 'states-of-origin',
      type: 'QUESTION',
      question: 'Typical incoming states of origin',
      answer: statesOfOriginString,
    });
    items.push({
      id: 'nature-of-business',
      type: 'QUESTION',
      question: 'Nature of business conducted for transfers',
      answer: natureOfBusiness,
    });
  }

  return {
    id: BUSINESS_ACTIVITY_SECTION_ID,
    title: BUSINESS_ACTIVITY_SECTION_TITLE,
    page: {
      id: ACCEPT_OR_TRANSMIT_WIRE_TRANSFER_PAGE_ID,
      title: 'Business Activity Section - ATM Location',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setAcceptWireTransferValues(
  acceptWireSection: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  acceptWireSection.page.items.forEach((item) => {
    if (item.id === 'your-business-accept-or-transmit-wire-transfers') {
      setValues(
        'your-business-accept-or-transmit-wire-transfers',
        item.answer === 'Yes' ? 'true' : 'false',
      );
    }
    if (item.id === 'states-of-origin') {
      const states = item.answer.split(',');
      setValues(item.id as keyof FormSchema, states);
    }
    if (item.id === 'nature-of-business') {
      setValues('nature-of-business', item.answer);
    }
  });
}
