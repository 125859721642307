import type {
  ItemDto,
  SectionDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import {
  BANKING_ACTIVITY_SECTION_ID,
  BANKING_ACTIVITY_SECTION_TITLE,
} from '@pages/Facilities/Sections/BankingActivity/data';
import type { UseFormSetValue } from 'react-hook-form';
import { z } from 'zod';
import type { FormSchema } from './types';

export const BANKING_ACTIVITY_PAGE_ID = 'banking-activity-page';

export class BankingDetailType {
  static readonly REGULAR_CASH: BankingDetailType = new BankingDetailType(
    'REGULAR_CASH',
    'Regular Cash Deposits',
    'regular-cash-amount',
    'regular-cash-transactions',
  );

  static readonly CASH_WITHDRAWALS: BankingDetailType = new BankingDetailType(
    'CASH_WITHDRAWALS',
    'Cash Withdrawals',
    'cash-withdrawals-amount',
    'cash-withdrawals-transactions',
  );

  static readonly INCOMING_ACH: BankingDetailType = new BankingDetailType(
    'INCOMING_ACH',
    'Incoming ACH',
    'incoming-ach-amount',
    'incoming-ach-transactions',
  );

  static readonly OUTGOING_ACH: BankingDetailType = new BankingDetailType(
    'OUTGOING_ACH',
    'Outgoing ACH (e.g. Payroll)',
    'outgoing-ach-amount',
    'outgoing-ach-transactions',
  );

  static readonly WIRES: BankingDetailType = new BankingDetailType(
    'WIRES',
    'Incoming/Outgoing Wires',
    'wires-amount',
    'wires-transactions',
  );

  static readonly MRDC: BankingDetailType = new BankingDetailType(
    'MRDC',
    'Mobile or Remote Deposit (Check) MRDC',
    'mrdc-amount',
    'mrdc-transactions',
  );

  static readonly ATM: BankingDetailType = new BankingDetailType(
    'ATM',
    'ATM Usage (Pulling money out of ATM)',
    'atm-amount',
    'atm-transactions',
  );

  private constructor(
    private readonly key: string,
    public readonly label: string,
    public readonly amountId: string,
    public readonly transactionId: string,
  ) {}

  toString(): string {
    return this.key;
  }
}

export const amountsSchema = z.object({
  [BankingDetailType.REGULAR_CASH.amountId]: z.string().min(1),
  [BankingDetailType.CASH_WITHDRAWALS.amountId]: z.string().min(1),
  [BankingDetailType.INCOMING_ACH.amountId]: z.string().min(1),
  [BankingDetailType.OUTGOING_ACH.amountId]: z.string().min(1),
  [BankingDetailType.WIRES.amountId]: z.string().min(1),
  [BankingDetailType.MRDC.amountId]: z.string().min(1),
  [BankingDetailType.ATM.amountId]: z.string().min(1),
});

export const transactionsSchema = z.object({
  [BankingDetailType.REGULAR_CASH.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.CASH_WITHDRAWALS.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.INCOMING_ACH.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.OUTGOING_ACH.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.WIRES.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.MRDC.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.ATM.transactionId]: z
    .string()
    .min(1, 'Transactions are not valid'),
  [BankingDetailType.REGULAR_CASH.amountId]: z
    .string()
    .min(1, 'Amount is not valid'),
  [BankingDetailType.CASH_WITHDRAWALS.amountId]: z
    .string()
    .min(1, 'Amount is not valid'),
  [BankingDetailType.INCOMING_ACH.amountId]: z
    .string()
    .min(1, 'Amount is not valid'),
  [BankingDetailType.OUTGOING_ACH.amountId]: z
    .string()
    .min(1, 'Amount is not valid'),
  [BankingDetailType.WIRES.amountId]: z.string().min(1, 'Amount is not valid'),
  [BankingDetailType.MRDC.amountId]: z.string().min(1, 'Amount is not valid'),
  [BankingDetailType.ATM.amountId]: z.string().min(1, 'Amount is not valid'),
});

export const formSchema = amountsSchema.merge(transactionsSchema);

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const items: ItemDto[] = [];

  Object.values(BankingDetailType).forEach((item: BankingDetailType) => {
    items.push(
      {
        id: item.amountId,
        type: 'QUESTION',
        question: item.label + ' amount per month',
        answer: businessData[item.amountId] as string,
      },
      {
        id: item.transactionId,
        type: 'QUESTION',
        question: item.label + ' transaction per month',
        answer: businessData[item.transactionId] as string,
      },
    );
  });

  return {
    id: BANKING_ACTIVITY_SECTION_ID,
    title: BANKING_ACTIVITY_SECTION_TITLE,
    page: {
      id: BANKING_ACTIVITY_PAGE_ID,
      title: 'Banking Activity Section - Detail',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setBankingActivityValues(
  detailType: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  detailType.page.items.forEach((item) => {
    setValues(item.id, item.answer);
  });
}
