import type { UseQueryResult } from '@tanstack/react-query';
import { useQueryWithCallbacks } from '@/api/utils/useQueryWithCallbacks';
import type { QueryOptionsRequestCallback } from '@/api/types';

import type { Business } from '@/types/business';
import type { SystemUserAuthResponse, User } from '@/types/user';
import type { IndividualOwner } from '@/types/owner-relationship';
import { useRoles } from '@/store/useRoles';
import _omit from 'lodash/omit';

import getAuthenticatedUser from './getAuthenticatedUser';

type TAuthenticatedUser = User & { businesses: Business[] } & {
  SystemUser: SystemUserAuthResponse;
} & { IndividualOwner: IndividualOwner[] };

const useAuthenticatedUser = (
  queryOptions?: QueryOptionsRequestCallback<TAuthenticatedUser> & {
    isEmailVerified?: boolean;
  },
): UseQueryResult<TAuthenticatedUser, Error> => {
  const setRoles = useRoles((state) => state.setRoles);
  const originalOptions = _omit(queryOptions, ['isEmailVerified']);

  return useQueryWithCallbacks<TAuthenticatedUser, Error>({
    queryKey: ['getAuthenticatedUser'],
    queryFn: async () =>
      await getAuthenticatedUser(queryOptions?.isEmailVerified),
    ...originalOptions,
    onSuccess: (data: { roles: any }) => {
      originalOptions?.onSuccess?.(data);
      setRoles(data?.roles ?? []);
    },
  });
};

export default useAuthenticatedUser;
