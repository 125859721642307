export const COUNTRY_LIST = ['US'] as const;

export const OPERATOR_COUNTRY_LIST = [
  {
    id: 'US',
    label: 'US',
    value: 'US',
  },
  {
    id: 'CA',
    label: 'Canada',
    value: 'CA',
  },
];

export type TCountry = 'US' | 'CA' | '';
