import { styled } from '@mui/material/styles';

export const StyledHelperTextContentWrapper = styled('span')`
  display: flex;
  align-items: center;
`;

export const selectDisplayStyles: React.CSSProperties = {
  height: '2.2rem',
  minHeight: 'auto',
  padding: '1.2rem 1.6rem',
};
