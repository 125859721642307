import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import LoadingComponent from '@components/UI/LoadingComponent';

import { ICONS } from '@/constants/icons';
import { Box } from '@mui/material';

interface ButtonProps {
  disabled?: boolean;
  show?: boolean;
  text?: string;
  danger?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

interface ControlBarProps {
  backButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
  continueButtonProps?: ButtonProps;
  isLoading?: boolean;
  loadingText?: string;
  showInfoText?: boolean;
}

const ControlBar = (props: ControlBarProps): JSX.Element => {
  const {
    backButtonProps,
    nextButtonProps,
    continueButtonProps,
    isLoading,
    loadingText = 'Saving your information',
    showInfoText = false,
  } = props;

  const {
    disabled: disabledBackButton,
    onClick: onClickBackButton,
    show: showBackButton = true,
    text: backButtonText = 'Back',
    danger: backButtonDanger = false,
  } = backButtonProps ?? {};

  const {
    disabled: disabledNextButton,
    isLoading: nextButtonIsLoading,
    onClick: onClickNextButton,
    show: showNextButton = true,
    text: nextButtonText = 'Next',
    danger: nextButtonDanger = false,
  } = nextButtonProps ?? {};

  const {
    disabled: disabledContinueButton,
    isLoading: continueButtonIsLoading,
    onClick: onClickContinueButton,
    show: showContinueButton = false,
    text: continueButtonText = 'Save & Continue',
    danger: continueButtonDanger = false,
  } = continueButtonProps ?? {};

  const infoText = `You can save and continue anywhere throughout the application process. You will be returned to the beginning of the section you were working in when logged out of the application.`;

  return (
    <Stack mt={4} spacing={4} width="100%">
      {isLoading && <LoadingComponent text={loadingText} />}

      {showInfoText && <Typography variant="caption">{infoText}</Typography>}

      <Stack
        direction="row"
        component="footer"
        sx={{
          width: '100%',
          borderTop: '0.2rem solid',
          borderColor: 'grey.100',
          justifyContent: 'space-between',
          paddingTop: 4,
        }}
      >
        {showBackButton ? (
          <Button
            startIcon={<ICONS.ArrowRoundBack />}
            disabled={disabledBackButton}
            onClick={onClickBackButton}
            color={backButtonDanger ? 'error' : undefined}
            size="large"
          >
            {backButtonText}
          </Button>
        ) : (
          <Box></Box>
        )}

        <Stack direction={'row'} gap={'16px'}>
          {showNextButton && (
            <Button
              variant="contained"
              disabled={disabledNextButton ?? nextButtonIsLoading}
              onClick={onClickNextButton}
              color={nextButtonDanger ? 'error' : undefined}
              size="large"
              sx={{
                gap: '5px',
              }}
            >
              {nextButtonIsLoading && (
                <CircularProgress color="inherit" size={14} />
              )}
              {nextButtonText}
            </Button>
          )}

          {showContinueButton && (
            <Button
              variant="contained"
              disabled={disabledContinueButton ?? continueButtonIsLoading}
              onClick={onClickContinueButton}
              color={continueButtonDanger ? 'error' : undefined}
              size="large"
              sx={{
                gap: '5px',
              }}
            >
              {nextButtonIsLoading && (
                <CircularProgress color="inherit" size={14} />
              )}
              {continueButtonText}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ControlBar;
