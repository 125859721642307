import { CSS_VARS } from '@/constants/css-vars';
import { ICONS } from '@/constants/icons';
import { isNaN } from 'lodash';
import { formatMoneyDisplay } from '@/utils/format';
import type { InputStatus } from './types';

const ICON_SIZE = 12;

export const inputStatusColor: Record<InputStatus, string> = {
  Error: CSS_VARS.Colors.Error.Dark,
  Info: CSS_VARS.Colors.BrandBlue[400],
  Success: CSS_VARS.Colors.Success.Dark,
};

export const inputStatusIcon: Record<InputStatus, JSX.Element> = {
  Error: (
    <ICONS.Close color={`var(${inputStatusColor.Error})`} size={ICON_SIZE} />
  ),
  Info: <ICONS.Info color={`var(${inputStatusColor.Info})`} size={ICON_SIZE} />,
  Success: (
    <ICONS.CheckmarkIcon
      color={`var(${inputStatusColor.Success})`}
      size={ICON_SIZE}
    />
  ),
};

export const getInputStatus = (invalid?: boolean): InputStatus => {
  if (invalid == null) return 'Info';

  return invalid ? 'Error' : 'Success';
};

export const isInputInvalid = <FieldErrors extends Record<string, unknown>>(
  formFields?: FieldErrors,
): boolean | undefined => {
  if (formFields == null) return;

  return Boolean(formFields);
};

export const isMonthlyLimitInvalid = <
  FieldErrors extends Record<string, unknown>,
>(
  mainBalance: number,
  monthlyLimitCurrent: number,
  formFields?: FieldErrors,
): boolean | undefined => {
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  return isInputInvalid(formFields) || mainBalance < monthlyLimitCurrent;
};

export const checkInvalidDebitLimit = (
  mainBalance: number,
  currentDebitLimit: number,
): string | undefined => {
  if (currentDebitLimit === 0 || isNaN(currentDebitLimit)) return undefined;
  if (mainBalance < currentDebitLimit)
    return `The current available balance in main account is ${formatMoneyDisplay(
      mainBalance,
    )}. Debit limit cannot exceed that amount`;

  return undefined;
};
