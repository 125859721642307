import fetcher from '@/utils/fetcher';

import type { GetBusinessApplicationStatusResponse } from './type';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getBusinessValidation = (
  businessId: string,
): Promise<GetBusinessApplicationStatusResponse> =>
  fetcher<GetBusinessApplicationStatusResponse>({
    endpoint: `/business-validation/applicationStatus/${businessId}`,
  });

export default getBusinessValidation;
