import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import type { Breakpoint } from '@mui/system';

interface Props {
  open: boolean;
  isLoading: boolean;
  maxWidth?: Breakpoint;
  title: string;
  description: string | JSX.Element;
  btnConfirmColor?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
  cancelText?: string;
  confirmText?: string;
  headerAlign?: 'center' | 'left' | 'right';
  onClose: () => void;
  onConfirm: () => void;
}

const ModalConfirm = ({
  open,
  isLoading,
  maxWidth = 'xs',
  title,
  description,
  btnConfirmColor = 'primary',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  headerAlign = 'center',
  onClose,
  onConfirm,
}: Props): JSX.Element => {
  return (
    <Dialog
      onClose={isLoading ? () => console.log : onClose}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogContent
        sx={{
          alignItems: { headerAlign },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant={'h4'}>{title}</Typography>

        <Typography my={2}>{description}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: '16px' }}>
        <Button
          onClick={onClose}
          disabled={isLoading}
          sx={{ minWidth: '110px' }}
        >
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isLoading}
          variant="contained"
          sx={{ minWidth: '110px' }}
          color={btnConfirmColor}
        >
          {isLoading && (
            <CircularProgress color="inherit" size={14} sx={{ mr: '2px' }} />
          )}
          <span>{confirmText}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirm;
