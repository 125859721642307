import { nanoid } from 'nanoid';

import { CSS_VARS } from '@/constants/css-vars';
import { ICONS } from '@/constants/icons';
import { PAYBOTIC_CONTACT_PHONE, PAYBOTIC_INFO } from '@/constants/misc';

import type { FooterItem } from './components';

const iconProps = {
  color: `var(${CSS_VARS.Colors.BrandBlue[200]})`,
  size: '1.2em',
};

export const footerData: Array<
  React.ComponentProps<typeof FooterItem> & {
    id: string;
  }
> = [
  {
    id: nanoid(),
    icon: <ICONS.MailOutline color={iconProps.color} size={iconProps.size} />,
    text: PAYBOTIC_INFO.email,
    anchorType: 'mailto',
  },
  {
    id: nanoid(),
    icon: <ICONS.Phone color={iconProps.color} size={iconProps.size} />,
    text: PAYBOTIC_CONTACT_PHONE.formatted,
    anchorType: 'tel',
  },
];
