import type { TooltipProps } from '@mui/material/Tooltip';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { CSS_VARS } from '@/constants/css-vars';

const Tooltip = (props: TooltipProps): JSX.Element => {
  const { className, ...rest } = props;

  return (
    <MuiTooltip
      {...rest}
      classes={{ popper: className }}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          fontSize: '1.2rem',
          border: `0.1rem solid var(${CSS_VARS.Colors.BrandBlue[200]})`,
        },
      }}
    />
  );
};

export default Tooltip;
