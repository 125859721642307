import fetcher from '@/utils/fetcher';
import type { FacilityLocationResponse } from './type';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getFacilities = (
  businessId: string,
  showInformation: boolean,
): Promise<FacilityLocationResponse[]> =>
  fetcher<FacilityLocationResponse[]>({
    endpoint: `/businesses/${businessId}/facilities?showInformation=${
      showInformation ? 'true' : 'false'
    }`,
  });

export default getFacilities;
