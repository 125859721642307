import type { BusinessApplicationProgress } from '@/types/business';
import fetcher from '@/utils/fetcher';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getBusinessApplicationProgress = (
  businessId: string,
): Promise<BusinessApplicationProgress> =>
  fetcher<BusinessApplicationProgress>({
    endpoint: `/businesses/${businessId}/application-progress`,
  });

export default getBusinessApplicationProgress;
