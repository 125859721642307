import { useQuery, UseQueryResult } from '@tanstack/react-query';
import type { BusinessDocument, BusinessDocumentType } from '@/types/business';
import getBusinessDocuments from './getBusinessDocuments';
import type { QueryOptionsRequest } from '@/api/types';

const useGetBusinessDocuments = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>(
  businessId: string,
  facilityId?: string,
  ownerId?: string,
  queryOptions?: QueryOptionsRequest<Array<BusinessDocument<DocumentType>>>,
): UseQueryResult<Array<BusinessDocument<DocumentType>>, Error> => {
  return useQuery<Array<BusinessDocument<DocumentType>>, Error>({
    queryKey: ['getBusinessDocuments'],
    queryFn: async () =>
      await getBusinessDocuments(businessId, facilityId, ownerId),
    ...queryOptions,
  });
};

export default useGetBusinessDocuments;
