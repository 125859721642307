interface IIdleTimerProps {
  timeout: number;
  onTimeout: () => void;
}

class IdleTimer {
  timeout: number;
  interval: number | undefined;
  timeoutTracker: number | undefined;
  eventHandler: () => void;
  onTimeout: () => void;

  constructor({ timeout, onTimeout }: IIdleTimerProps) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    const expiredTime = parseInt(
      localStorage.getItem('_expiredTime') ?? '0',
      10,
    );

    if (expiredTime > 0 && expiredTime < Date.now()) {
      onTimeout();
    }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval(): void {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem('_expiredTime') ?? '0',
        10,
      );
      if (expiredTime < Date.now()) {
        console.log('Timeout');
        this.onTimeout();
        this.cleanUp();
      }
    }, 1000);
  }

  updateExpiredTime(): void {
    if (this.timeoutTracker != null) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      localStorage.setItem(
        '_expiredTime',
        `${Date.now() + this.timeout * 1000}`,
      );
    }, 300);
  }

  tracker(): void {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp(): void {
    localStorage.removeItem('_expiredTime');
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
