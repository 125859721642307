import { API_URL } from '@/constants/env';
import type { DecodedJWT } from '@/types/jwt';
import * as Sentry from '@sentry/react';
import { jwtDecode } from 'jwt-decode';
import { isEmpty } from 'lodash';
interface FetcherArgs {
  endpoint: string;
  body?: BodyInit;
  headers?: HeadersInit;
  method?: 'DELETE' | 'GET' | 'PATCH' | 'POST';
  isBlob?: boolean;
  userToken: string;
  sessionKey?: string;
}

const publicFetcher = async <Data = unknown>(
  args: FetcherArgs,
): Promise<Data> => {
  const {
    endpoint,
    body,
    headers,
    method = 'GET',
    isBlob = false,
    userToken,
    sessionKey,
  } = args;

  const url = `${API_URL}${endpoint}`;

  const getUser = (): unknown => {
    try {
      const token = userToken;
      const userInfo: DecodedJWT = jwtDecode(token);
      const email = (userInfo.email as string) || null;

      return email;
    } catch (e) {
      Sentry.captureException(e);
    }
    return null;
  };

  const requestOptions = {
    body,
    headers: {
      ...headers,
    },
    method,
  };

  requestOptions.headers = {
    'Content-Type': 'application/json',
    Authorization: userToken == null ? '' : `Bearer ${userToken}`,
    ...(!isEmpty(sessionKey) && { 'session-key': sessionKey }),
    ...headers,
  };

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    const user = getUser() as string;

    Sentry.setUser(null);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const errorMessage = await response.json();
    Sentry.captureMessage(JSON.stringify(errorMessage));

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    Sentry.setUser({
      email: user,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
    throw new Error(errorMessage.message ?? response.statusText);
  }

  return isBlob
    ? await (response.blob() as unknown as Promise<Data>)
    : await (response.json() as Promise<Data>);
};

export default publicFetcher;
