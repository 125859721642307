import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

export const useIsCurrentPath = (
  path?: string,
  childPaths?: string[],
): boolean => {
  const location = useLocation();

  const isCurrentPath = useBoolean(false);

  useEffect(() => {
    let isMatched = !!(path && matchPath(path, location.pathname));

    if (!isMatched && childPaths?.length) {
      isMatched = childPaths.some((childPath) =>
        matchPath(childPath, location.pathname),
      );
    }

    isCurrentPath.setValue(!!isMatched);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentPath.value, location.pathname, path, childPaths?.join?.()]);

  return isCurrentPath.value;
};
