import type {
  BusinessApplicationProgress,
  BusinessApplicationProgressSection,
} from '@/types/business';
import fetcher from '@/utils/fetcher';
export interface UpdateBusinessApplicationProgress {
  businessId: string;
  sections?: BusinessApplicationProgressSection[];
  pagesCompleted?: number;
}

// eslint-disable-next-line @typescript-eslint/promise-function-async
const updateBusinessApplicationProgress = ({
  businessId,
  sections,
  pagesCompleted,
}: UpdateBusinessApplicationProgress): Promise<BusinessApplicationProgress> => {
  // eslint-disable-next-line @typescript-eslint/return-await
  return fetcher<BusinessApplicationProgress>({
    body: JSON.stringify({ sections, pagesCompleted }),
    endpoint: `/businesses/${businessId}/application-progress`,
    method: 'PATCH',
  });
};

export default updateBusinessApplicationProgress;
