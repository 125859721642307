import type { FormSchema } from './schema';
import type {
  SectionDto,
  ItemDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import {
  BANKING_ACTIVITY_SECTION_ID,
  BANKING_ACTIVITY_SECTION_TITLE,
} from '@pages/Facilities/Sections/BankingActivity/data';
import type { UseFormSetValue } from 'react-hook-form';

export const PRIMARY_PURPOSE_PAGE_ID = 'primary-purpose-page';

interface AutocompleteOption {
  label: string;
  id: string;
}
export const ACCOUNT_TYPES: readonly AutocompleteOption[] = [
  { label: 'Operating Account', id: 'operating-account' },
  { label: 'Payroll', id: 'payroll' },
  { label: 'Taxes', id: 'taxes' },
] as const;

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const { 'account-type': accountType } = businessData;

  const accountTypeLabel = ACCOUNT_TYPES.find(
    (item) => item.id === accountType,
  );
  const items: ItemDto[] = [
    {
      id: 'account-type',
      type: 'QUESTION',
      question: 'What is the primary purpose for this account?',
      answer: accountTypeLabel?.label ?? '',
    },
  ];

  return {
    id: BANKING_ACTIVITY_SECTION_ID,
    title: BANKING_ACTIVITY_SECTION_TITLE,
    page: {
      id: PRIMARY_PURPOSE_PAGE_ID,
      title: 'Banking Activity Section - Primary Purpose',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setPrimaryPurposeValues(
  detailType: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  detailType.page.items.forEach((item) => {
    const accountTypeId = ACCOUNT_TYPES.find(
      (account) => account.label === item.answer,
    );
    setValues(item.id as keyof FormSchema, accountTypeId?.id ?? '');
  });
}
