import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import type {
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
} from 'react-hook-form';

import { FIXED_COUNTRY } from '@/constants/misc';
import { COUNTRY_LIST } from '@/constants/us-countries';
import { US_STATES, TStateOrProvince } from '@/constants/us-states';

import InputDropdown from '@/components/Forms/InputDropdown';
import InputText from '@/components/Forms/InputText';

import type { AddressFormSchema } from './type';
import type { SelectChangeEvent } from '@mui/material/Select';
import type { GetBusinessInformationFeedbacks } from '@/api/hooks/useGetBusinessInformationFeedbacks/types';

interface AddressFormProps {
  watch: UseFormWatch<AddressFormSchema>;
  setValue: UseFormSetValue<AddressFormSchema>;
  register: UseFormRegister<AddressFormSchema>;
  errors: FieldErrors<AddressFormSchema>;
  stateAddress: TStateOrProvince;
  handleAddressChange: (data: SelectChangeEvent<unknown>) => void;
  feedbackFields?: GetBusinessInformationFeedbacks[];
  isUnderReview?: boolean;
}

const normalizedUsStates = US_STATES.map((usState) => {
  return {
    id: usState,
    value: usState,
    label: usState,
  };
});

const normalizedCountryCode = COUNTRY_LIST.map((country) => {
  return {
    id: country,
    value: country,
    label: country,
  };
});

function AddressForm(props: AddressFormProps): JSX.Element {
  const {
    watch,
    setValue,
    register,
    errors,
    stateAddress,
    handleAddressChange,
    feedbackFields = [],
    isUnderReview = false,
  } = props;

  return (
    <Box component="section" sx={{ marginBottom: '2rem' }}>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ marginBottom: '2rem', display: 'flex' }}
      >
        <Grid item xs={12} md={4}>
          <InputText
            {...register('street-address')}
            value={watch('street-address')}
            label="Street Address"
            name="street-address"
            required
            width={'100%'}
            fieldErrors={errors['street-address']}
            helperText={errors['street-address']?.message as string}
            showHelperText
            hasTooltip
            onChange={(newValue) =>
              setValue('street-address', newValue?.target?.value)
            }
            disabled={
              feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.address_line_1', 'street-address'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.address_line_1', 'street-address'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputText
            {...register('unit-number')}
            label="Apartment / Suite #"
            name="unit-number"
            placeholder="Unit/Suite #"
            width={'100%'}
            fieldErrors={errors['unit-number']}
            helperText={errors['unit-number']?.message}
            showHelperText
            disabled={
              feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.address_line_2', 'unit-number'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.address_line_2', 'unit-number'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputText
            {...register('city')}
            value={watch('city')}
            label="City"
            name="city"
            required
            width={'100%'}
            fieldErrors={errors.city}
            helperText={errors.city?.message}
            showHelperText
            hasTooltip
            onChange={(newValue) => setValue('city', newValue?.target?.value)}
            disabled={
              feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.city', 'city'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.city', 'city'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ marginBottom: '2rem', display: 'flex' }}
      >
        <Grid item xs={12} md={4}>
          <InputDropdown
            {...register('state')}
            label={'State'}
            fieldErrors={errors.state}
            helperText={errors.state?.message}
            options={normalizedUsStates}
            placeholder="Select"
            showHelperText
            width={'100%'}
            value={stateAddress}
            required
            onChange={handleAddressChange}
            disabled={
              feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.state', 'state'].includes(feedback.section),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.state', 'state'].includes(feedback.section),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputText
            {...register('postal-code')}
            label="Postal Code"
            name="postal-code"
            type={'number'}
            required
            width={'100%'}
            fieldErrors={errors['postal-code']}
            helperText={errors['postal-code']?.message}
            showHelperText
            disabled={
              feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.postal_code', 'postal-code'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.postal_code', 'postal-code'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputDropdown<typeof COUNTRY_LIST[number]>
            {...register('country')}
            defaultValue={FIXED_COUNTRY}
            fieldErrors={errors.country}
            helperText={errors.country?.message}
            label={'Country'}
            name={'country'}
            required
            options={normalizedCountryCode}
            placeholder="Select"
            showHelperText
            width={'100%'}
            disabled={
              feedbackFields.length || isUnderReview
                ? !(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.country_code', 'country'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
            errorFlag={
              feedbackFields.length
                ? !!(feedbackFields ?? []).filter((feedback) =>
                    ['legal_address.country_code', 'country'].includes(
                      feedback.section,
                    ),
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddressForm;
