import type { IList } from '@/types/account';
import { get } from 'lodash';
import { nanoid } from 'nanoid';

interface Option<TL, FL, TV, FV> {
  id: string;
  label: TL | FL;
  value: TV | FV;
}

interface GetBooleanOptionListArgs<TL, FL, TV, FV> {
  labels: readonly [TL, FL];
  values: readonly [TV, FV];
}

export const getRadioOptionList = <
  TL extends string,
  FL extends string,
  TV extends string,
  FV extends string,
>(
  args: GetBooleanOptionListArgs<TL, FL, TV, FV>,
): Array<Option<TL, FL, TV, FV>> => {
  const { labels, values } = args;

  const [trueLabel, falseLabel] = labels;

  const [trueValue, falseValue] = values;

  return new Array<null>(2)
    .fill(null)
    .map<Option<TL, FL, TV, FV>>((_, index) => {
      const isFirstIndex = index === 0;

      const parsedBooleanValue = isFirstIndex ? trueValue : falseValue;

      return {
        id: nanoid(),
        label: isFirstIndex ? trueLabel : falseLabel,
        value: parsedBooleanValue,
      };
    });
};

interface IOptionList {
  id: string;
  label: string;
  value: string;
  hasPendingTransaction: boolean;
  data: unknown;
}

export const generateCardOptionList = (list: IList[]): IOptionList[] => {
  return list.map((item) => {
    const nickName = get(item, 'nickname', 'Card');
    const number = get(item, 'account_identifiers.number', '');

    return {
      id: item.id,
      label: `${nickName} • ${number}`,
      value: item.id,
      data: item,
      hasPendingTransaction: false,
    };
  });
};

type IAccountGroup = Record<string, IList[]>;
export type IAccountOption = Record<string, IOptionList[]>;

export const groupAccountByInstitution = (list: IList[]): IAccountOption => {
  const rawGroup = list.reduce((acc: IAccountGroup, item: IList) => {
    const institutionId = get(item, 'vendor_data.institution_id', '');
    if (acc[institutionId]) {
      acc[institutionId]?.push(item);
      return acc;
    }

    acc[institutionId] = [item];
    return acc;
  }, {});

  return Object.keys(rawGroup).reduce((acc: IAccountOption, groupKey) => {
    acc[groupKey] = generateCardOptionList(rawGroup[groupKey] ?? []);
    return acc;
  }, {});
};
