import { CSS_VARS } from '@/constants/css-vars';

import { ICONS } from '@/constants/icons';

type Status = 'Error' | 'Info' | 'Success';

export const statusColor: Record<Status, string> = {
  Error: CSS_VARS.Colors.Error.Dark,
  Info: CSS_VARS.Colors.BrandBlue[400],
  Success: CSS_VARS.Colors.Success.Dark,
};

export const statusIcon: Record<Status, JSX.Element> = {
  Error: <ICONS.Close color={`var(${statusColor.Error})`} size={12} />,
  Info: <ICONS.Info color={`var(${statusColor.Info})`} size={12} />,
  Success: (
    <ICONS.CheckmarkIcon color={`var(${statusColor.Success})`} size={12} />
  ),
};

export const getInputStatus = (invalid?: boolean): Status => {
  if (invalid == null) return 'Info';

  return invalid ? 'Error' : 'Success';
};

export const isInvalid = <FieldErrors extends Record<string, unknown>>(
  formFields?: FieldErrors,
): boolean | undefined => {
  if (formFields == null) return;

  return Boolean(formFields);
};
