import type { InputBaseComponentProps } from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { forwardRef, useMemo, useState } from 'react';

import { CSS_VARS } from '@/constants/css-vars';

import HelperText from '../HelperText';

import { getInputStatus, isInputInvalid as isInputInvalidUtil } from '../utils';

import { inputStyles, StyledInputText, StyledWrapInput } from './styles';

import type { TextInputProps } from './types';
import Tooltip from '@/components/Tooltip';
import IconButton from '@mui/material/IconButton';
import { ICONS } from '@/constants/icons';
import { useTheme, useMediaQuery } from '@mui/material';

const DEFAULT_INPUT_PROPS: InputBaseComponentProps = {
  style: inputStyles,
};

interface ExtendedInputTextProps extends TextInputProps {
  preventPaste?: boolean;
  onKeyUp?: (e: { key: string; keyCode: number }) => void;
  isFilled?: boolean;
  isInline?: boolean;
  hasTooltip?: boolean;
  widthInput?: number;
  absoluteHelperText?: boolean;
}

const InputText = forwardRef<
  HTMLInputElement,
  ExtendedInputTextProps & { errorFlag?: boolean }
>((props, ref): JSX.Element => {
  const {
    disabled,
    endAdornment,
    fieldErrors,
    helperText,
    hideLabel = false,
    label,
    name,
    toolTipText,
    required = false,
    showHelperText = false,
    type = 'text',
    width,
    widthInput,
    preventPaste,
    errorFlag = false,
    inputProps = {},
    isFilled,
    isInline = false,
    hasTooltip = false,
    absoluteHelperText = false,
    ...restOfProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const describedBy = `${name}-helper-text`;

  const isInputInvalid = isInputInvalidUtil(fieldErrors);

  const status = getInputStatus(isInputInvalid);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const widthToRender = isMobile ? '100%' : width;

  const combinedInputProps: InputBaseComponentProps = useMemo(
    () => ({
      ...inputProps,
      style: {
        ...DEFAULT_INPUT_PROPS.style,
        ...inputProps.style,
      },
    }),
    [inputProps],
  );

  const handleFocus = (): void => {
    setIsFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    setIsFocused(false);
    if (restOfProps?.onBlur) {
      restOfProps.onBlur(event);
    }
  };

  const inputValueTooltip = (() => {
    if (!isFocused) {
      return restOfProps.value as string;
    }

    return '';
  })();

  return (
    <div
      style={{
        width: widthToRender ?? '20rem',
        ...(isInline && { display: 'flex' }),
      }}
    >
      {!hideLabel && (
        <InputLabel
          htmlFor={name}
          required={required}
          sx={{
            color: required ? `var(${CSS_VARS.Colors.Error.Dark})` : 'inherit',
            whiteSpace: 'unset',
            ...(isInline && { m: '1px 1rem 2rem 0', flex: 1 }),
          }}
        >
          <Typography display="inline" fontWeight={700} variant="body2">
            {label}
          </Typography>

          {toolTipText != null && (
            <Tooltip placement="right" title={toolTipText}>
              <IconButton
                size="small"
                sx={{ p: 0, ml: 0.5, color: 'primary.200' }}
              >
                <ICONS.Info />
              </IconButton>
            </Tooltip>
          )}
        </InputLabel>
      )}

      <StyledWrapInput
        width={widthInput}
        sx={{
          ...(absoluteHelperText && {
            paddingBottom: '20px',
          }),
        }}
      >
        {hasTooltip ? (
          <Tooltip placement="top" title={inputValueTooltip}>
            <StyledInputText
              aria-describedby={describedBy}
              disabled={disabled}
              endAdornment={endAdornment}
              error={isInputInvalid ?? errorFlag}
              fullWidth
              id={name}
              inputProps={combinedInputProps}
              name={name}
              inputRef={ref}
              required={required}
              type={type}
              onPaste={(e) => {
                if (preventPaste === true) {
                  e.preventDefault();
                  return false;
                }
                return true;
              }}
              isFilled={isFilled}
              {...restOfProps}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Tooltip>
        ) : (
          <StyledInputText
            aria-describedby={describedBy}
            disabled={disabled}
            endAdornment={endAdornment}
            error={isInputInvalid ?? errorFlag}
            fullWidth
            id={name}
            inputProps={combinedInputProps}
            name={name}
            inputRef={ref}
            required={required}
            type={type}
            onPaste={(e) => {
              if (preventPaste === true) {
                e.preventDefault();
                return false;
              }
              return true;
            }}
            isFilled={isFilled}
            {...restOfProps}
          />
        )}

        {showHelperText && (
          <HelperText
            describedBy={describedBy}
            helperText={helperText}
            status={status}
            absoluteHelperText={absoluteHelperText}
          />
        )}
      </StyledWrapInput>
    </div>
  );
});

if (import.meta.env.DEV) {
  InputText.displayName = 'InputText';
}

export default InputText;
