import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getApplicationFeedbacks from './getApplicationFeedbacks';
import type { GetApplicatonFeedbacks } from './types';
import type { QueryOptionsRequest } from '@/api/types';

const useGetApplicationFeedbacks = (
  facilityId: string,
  queryOptions?: QueryOptionsRequest<GetApplicatonFeedbacks[]>,
): UseQueryResult<GetApplicatonFeedbacks[], Error> => {
  return useQuery<GetApplicatonFeedbacks[], Error>({
    queryKey: ['getApplicationFeedbacks'],
    queryFn: async () => await getApplicationFeedbacks(facilityId),
    ...queryOptions,
  });
};

export default useGetApplicationFeedbacks;
