import type { BusinessDetails } from '@/types/business';

import fetcher from '@/utils/fetcher';

import type { UseGetBusinessVariables } from './types';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getBusiness = (
  variables: UseGetBusinessVariables<false>,
): Promise<BusinessDetails> => {
  const { businessId } = variables;

  return fetcher<BusinessDetails>({ endpoint: `/businesses/${businessId}` });
};

export default getBusiness;
