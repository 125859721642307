import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton, {
  listItemButtonClasses,
} from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { CSS_VARS } from '@/constants/css-vars';

import type { NavigationItem } from '@/features/ui/navigation';
import { IconStatus, navigationIcon } from '@/features/ui/navigation';

import { useIsCurrentPath } from '@/utils/hooks/useIsCurrentPath';
import type { BusinessApplicationProgressValue } from '@/types/business';

export type StepNavigationButtonProps = NavigationItem & {
  childPaths?: string[];
  isDisabled?: boolean;
  checkBox?: boolean;
  progressStatus?: BusinessApplicationProgressValue;
  onClick?: (id: NavigationItem['id']) => void;
};

const StepNavigationButton = (
  props: StepNavigationButtonProps,
): JSX.Element => {
  const {
    id,
    path,
    childPaths,
    text,
    isDisabled = false,
    checkBox = false,
    progressStatus = 'Selected',
    onClick,
  } = props;

  const isCurrent = useIsCurrentPath(path, childPaths);

  const textColor = isCurrent
    ? CSS_VARS.Colors.BrandBlue[400]
    : CSS_VARS.Colors.Gray[400];

  const handleNavigationButtonClick = (): void => {
    onClick?.(id);
  };

  const iconStatus =
    isCurrent && progressStatus === 'Selected'
      ? IconStatus.Pending
      : IconStatus[progressStatus];

  return (
    <ListItem disableGutters disablePadding>
      <Link
        aria-disabled={isDisabled}
        style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
        to={path ?? '#'}
      >
        <ListItemButton
          disabled={isDisabled}
          disableGutters
          selected={isCurrent}
          sx={{
            borderRadius: '0 5rem 5rem 0',
            padding: '1rem 30px 1rem 0',
            paddingLeft: '3.5rem',
            [`&.${listItemButtonClasses.selected},
              &.${listItemButtonClasses.selected}:hover`]: {
              backgroundColor: `var(${CSS_VARS.Colors.BrandBlue[100]})`,
            },
          }}
          onClick={handleNavigationButtonClick}
        >
          <Grid alignItems="center" container gap={2}>
            {checkBox && (
              <span style={{ display: 'flex' }}>
                {navigationIcon[iconStatus]}
              </span>
            )}

            <ListItemText disableTypography>
              <Typography
                color={`var(${textColor})`}
                fontWeight={isCurrent != null ? 700 : 500}
                noWrap
                variant="h5"
              >
                {text}
              </Typography>
            </ListItemText>
          </Grid>
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default StepNavigationButton;
