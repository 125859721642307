import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';

export const useBusinessId = (isUserAuthed = true): string | null => {
  const authenticatedUser = useAuthenticatedUser({
    enabled: isUserAuthed,
  });

  if (!isUserAuthed) return null;

  const { id: businessId } = authenticatedUser.data?.businesses?.[0] ?? {};

  if (businessId == null && authenticatedUser.isError) {
    throw new Error("Couldn't find a Business ID");
  }

  return businessId ?? null;
};
