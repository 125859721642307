import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

interface FooterItemProps {
  icon: React.ReactNode;
  text: string;
  anchorType?: 'tel' | 'mailto';
}

const FooterItem = memo((props: FooterItemProps): JSX.Element => {
  const { icon, text, anchorType } = props;

  return (
    <ListItem
      sx={{
        py: {
          xs: 0,
          md: '0.5rem',
        },
        px: 0,
      }}
    >
      <Grid alignItems="center" container gap={1} wrap="nowrap">
        {icon}

        <ListItemText>
          <Typography
            noWrap
            sx={{
              fontWeight: '500',
              fontSize: {
                xs: '1.3rem',
                md: '1.6rem',
              },
            }}
          >
            {anchorType != null ? (
              <Link
                href={`${anchorType}:${text}`}
                underline="none"
                color="inherit"
              >
                {text}
              </Link>
            ) : (
              text
            )}
          </Typography>
        </ListItemText>
      </Grid>
    </ListItem>
  );
});

if (import.meta.env.DEV) {
  FooterItem.displayName = 'FooterItem';
}

export default FooterItem;
