import { useQuery } from '@tanstack/react-query';
import { EndpointKeys } from '@/api/keys';
import { BusinessDetails } from '@/types/business';
import getBusiness from './getBusiness';

import type { UseGetBusinessOutput, UseGetBusinessVariables } from './types';

import type { QueryOptionsRequest } from '@/api/types';

const useGetBusiness = (
  variables: UseGetBusinessVariables<true>,
  queryOptions?: QueryOptionsRequest<BusinessDetails>,
): UseGetBusinessOutput => {
  const { businessId } = variables;

  const queryWillBeEnabled = typeof businessId === 'string';

  return useQuery<BusinessDetails, Error>({
    queryKey: [EndpointKeys.UseGetBusiness],
    queryFn: async () => await getBusiness({ businessId: businessId ?? '' }),
    enabled: queryWillBeEnabled,
    ...queryOptions,
  });
};

export default useGetBusiness;
