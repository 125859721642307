import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { useDebugger } from '@/store/useDebugger';

import { ICONS } from '@/constants/icons';
import { styled } from '@mui/material/styles';

import OtpInput from 'react-otp-input';

const StyledInputOTP = styled('input')(() => ({
  width: '40px !important',
  height: '40px !important',
  border: '1px solid #ccc',
  borderRadius: '4px',
  margin: '0 5px',
  fontSize: '18px',
  fontWeight: 'bold',
}));

const StyledDebuggerSpan = styled('span')(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: 30,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 20,
  color: 'red',
}));

const Debugger = () => {
  const { isDebug, setIsDebug } = useDebugger();
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [otp, setOTP] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSwitchDebugger = (): void => {
    const nextCount = count + 1;

    setCount(nextCount);

    if (!(nextCount % 5)) {
      if (!isDebug) {
        handleClickOpen();
      } else {
        setIsDebug(false);
      }
    }
  };

  useEffect(() => {
    if (otp === '1000') {
      setOTP('');
      setIsDebug(true);
      setOpen(false);
    }
  }, [otp]);

  return (
    <StyledDebuggerSpan onClick={onSwitchDebugger}>
      {isDebug && <ICONS.VscDebugConsole />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 9999, w: 300 }}
      >
        <DialogTitle id="alert-dialog-title">
          Enter code to enable debugger
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <OtpInput
            value={otp}
            onChange={setOTP}
            numInputs={4}
            renderInput={(props) => (
              <StyledInputOTP
                {...props}
                inputMode="numeric"
                autoComplete="one-time-code"
                pattern="\d{1}"
              />
            )}
          />
        </DialogContent>
      </Dialog>
    </StyledDebuggerSpan>
  );
};

export default Debugger;
