import { nanoid } from 'nanoid';
import type {
  SectionDto,
  ItemDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import type { FormSchema } from './schema';
import {
  BUSINESS_DETAILS_SECTION_ID,
  BUSINESS_DETAILS_SECTION_TITLE,
} from '@pages/Facilities/Sections/Details/types';
import type { UseFormSetValue } from 'react-hook-form';

export const DETAIL_TYPE_PAGE_ID = 'facility-detail-page';
export const TIER_ONE_OPTIONS = [
  'Growing cannabis (cultivation)',
  'Transporting cannabis (distribution)',
  'Making cannabis products (manufacturing)',
  'Selling cannabis (retail)',
] as const;

export const TIER_TWO_OPTIONS = [
  'Professional services and consultants (attorneys, accountants, etc.)',
  'Cultivation/ Manufacturing/ Consumption device Supplier',
  'Security service',
  'Marijuana testing service',
  'Human resource/payroll provider',
  'Marijuana-related software provider.',
  'Event organizer',
] as const;

interface Tier {
  id: string;
  value: string;
  label: string;
}

export const tierOneOptionList = TIER_ONE_OPTIONS.map<Tier>((tier) => {
  return {
    id: nanoid(),
    label: tier,
    value: tier.toLowerCase().replaceAll(' ', '-'),
  };
});

export const tierTwoOptionList = TIER_TWO_OPTIONS.map<Tier>((tier) => {
  return {
    id: nanoid(),
    label: tier,
    value: tier.toLowerCase().replaceAll(' ', '-'),
  };
});

export class DetailTierType {
  static readonly TIER_1: DetailTierType = new DetailTierType(
    'TIER_1',
    'Tier 1 (Direct)',
    'first-tier',
  );

  static readonly TIER_2: DetailTierType = new DetailTierType(
    'TIER_2',
    'Tier 2',
    'second-tier',
  );

  private constructor(
    private readonly key: string,
    public readonly label: string,
    public readonly id: string,
  ) {}

  toString(): string {
    return this.key;
  }
}

export function getNormalizedData(businessData: FormSchema): SectionDto {
  const items: ItemDto[] = [];

  Object.values(DetailTierType).forEach((tier: DetailTierType) => {
    if (businessData[tier.id] && items.length < 1) {
      const answers = tierOneOptionList.concat(tierTwoOptionList);
      const tierOption = answers.find(
        (option) => option.value === businessData[tier.id],
      );

      items.push({
        id: tier.id,
        type: 'QUESTION',
        question: tier.label,
        answer: tierOption?.label as string,
      });
    }
  });

  return {
    id: BUSINESS_DETAILS_SECTION_ID,
    title: BUSINESS_DETAILS_SECTION_TITLE,
    page: {
      id: DETAIL_TYPE_PAGE_ID,
      title: 'Business Detail Section - Type',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setDetailTypeValues(
  detailType: SectionDto,
  setValues: UseFormSetValue<FormSchema>,
): void {
  const item = detailType.page.items.at(0);
  if (item != null) {
    if (item.id === DetailTierType.TIER_1.id) {
      const tierOneOption = tierOneOptionList.find(
        (option) => option.label === item.answer,
      );

      setValues(item.id, tierOneOption?.value as string);
    }
    if (item.id === DetailTierType.TIER_2.id) {
      const tierTwoOption = tierTwoOptionList.find(
        (option) => option.label === item.answer,
      );
      setValues(item.id, tierTwoOption?.value as string);
    }
  }
}

export function getDetailTypeValue(detailType: SectionDto | undefined): string {
  const item = detailType?.page?.items?.at(0);
  if (item != null) {
    if (item.id === DetailTierType.TIER_1.id) {
      const tierOneOption = tierOneOptionList.find(
        (option) => option.label === item.answer,
      );

      return tierOneOption?.value as string;
    }
    if (item.id === DetailTierType.TIER_2.id) {
      const tierTwoOption = tierTwoOptionList.find(
        (option) => option.label === item.answer,
      );

      return tierTwoOption?.value as string;
    }
  }

  return '';
}
