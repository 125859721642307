import * as z from 'zod';

import {
  addressWithMailingFormSchema,
  addressWithOutMailingFormSchema,
} from '@components/Forms/Address/data';

export const formSchema = z.object({});

export type FormSchema = z.infer<typeof formSchema>;

export const facilityAddressWithMailingFormSchema =
  addressWithMailingFormSchema.merge(formSchema);
export const facilityAddressWithOutMailingFormSchema =
  addressWithOutMailingFormSchema.merge(formSchema);

export type FacilityWithMailingAddress = z.infer<
  typeof facilityAddressWithMailingFormSchema
>;
export type AddressesFormSchema =
  | FacilityWithMailingAddress
  | z.infer<typeof facilityAddressWithOutMailingFormSchema>;
