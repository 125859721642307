/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Router, { PublicRouter } from '@components/Router';
import { useSentry } from '@/utils/useSentry';
import { useLocation } from 'react-router-dom';
import { MEASUREMENT_ID } from '@/constants/env';
import Debugger from '@/components/Debugger';
import { getLocationInfo } from '@/constants/page-mapping-ga';
import { GA } from '@/utils/ga';

ReactGA.initialize(MEASUREMENT_ID);

function App(): JSX.Element {
  useSentry();
  const location = useLocation();
  const isPublic = location.pathname.startsWith('/public');

  useEffect(() => {
    if (['/', '/public'].includes(location.pathname)) return;

    const { page, title } = getLocationInfo(location.pathname) as {
      title: string;
      page: string;
    };

    GA.send({ page, title, hitType: 'pageview' });
  }, [location.pathname]);

  return (
    <>
      {isPublic ? <PublicRouter /> : <Router />}
      <Debugger />
    </>
  );
}

export default App;
