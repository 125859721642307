import type { UseMutationResult, MutationFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import updateBusinessApplicationProgress, {
  UpdateBusinessApplicationProgress,
} from './updateBusinessApplicationProgress';

const useUpdateBusinessApplicationProgress = (): UseMutationResult<
  UpdateBusinessApplicationProgress,
  Error,
  UpdateBusinessApplicationProgress
> =>
  useMutation<
    UpdateBusinessApplicationProgress,
    Error,
    UpdateBusinessApplicationProgress,
    MutationFunction<UpdateBusinessApplicationProgress, UpdateBusinessApplicationProgress>

  >({
    mutationFn: updateBusinessApplicationProgress as MutationFunction<UpdateBusinessApplicationProgress, UpdateBusinessApplicationProgress>,
  });
export default useUpdateBusinessApplicationProgress;
