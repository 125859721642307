import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';
import useGetFacilityById from '@/api/hooks/useGetFacilityById';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BusinessApplicationProgressValue,
  BusinessDetails,
} from '@/types/business';
import { Router } from '@/constants/router';
import type { NavigationItem } from '@/features/ui/navigation';
import _ from 'lodash';
import { LOCATION_DOCS_PAGE_ID } from '@/pages/Facilities/Sections/LocationDocs/data';
import { ACCEPT_OR_TRANSMIT_WIRE_TRANSFER_PAGE_ID } from '@/pages/Facilities/Sections/BusinessActivity/AcceptOrTransmitWireTransfers/data';
import { PRIMARY_BUSINESS_PAGE_ID } from '@/pages/Facilities/Sections/BankingActivity/PrimaryBusiness/data';
import { TRANSPORTATION_PAGE_ID } from '@/pages/Facilities/Sections/Details/Transportation/data';
import { StepNavigationButtonProps } from '@/components/Sidebar/SideBarNavigation/components/StepNavigation/StepNavigationButton';

const NAME_SECTION_ID = 'name-section';
const BUSINESS_DETAILS_SECTION_ID = 'business-details-section';
const BANKING_ACTIVITY_SECTION_ID = 'banking-activity-section';
const BUSINESS_ACTIVITY_SECTION_ID = 'business-activity-section';
const LOCATION_DOCS_SECTION_ID = 'location-docs-section';
const REVIEW_SIGN_SECTION_ID = 'review-sign-section';

const lastPageMap = {
  [NAME_SECTION_ID]: '',
  [BUSINESS_DETAILS_SECTION_ID]: TRANSPORTATION_PAGE_ID,
  [BANKING_ACTIVITY_SECTION_ID]: PRIMARY_BUSINESS_PAGE_ID,
  [BUSINESS_ACTIVITY_SECTION_ID]: ACCEPT_OR_TRANSMIT_WIRE_TRANSFER_PAGE_ID,
  [LOCATION_DOCS_SECTION_ID]: LOCATION_DOCS_PAGE_ID,
  [REVIEW_SIGN_SECTION_ID]: '',
};

const completedPathMap = {
  [BUSINESS_DETAILS_SECTION_ID]: Router.BankAccount.Facilities.Details.Review,
  [BANKING_ACTIVITY_SECTION_ID]:
    Router.BankAccount.Facilities.BankingActivity.Review,
  [BUSINESS_ACTIVITY_SECTION_ID]:
    Router.BankAccount.Facilities.BusinessActivity.Review,
};

const newFacilityItems: StepNavigationButtonProps[] = [
  {
    id: NAME_SECTION_ID,
    path: Router.BankAccount.FacilitiesLocations.AddNewFacility,
    text: 'Name',
  },
  {
    id: BUSINESS_DETAILS_SECTION_ID,
    path: Router.BankAccount.Facilities.Details.Type,
    text: 'Details',
    childPaths: _.map(Router.BankAccount.Facilities.Details, (path) => path),
  },
  {
    id: BANKING_ACTIVITY_SECTION_ID,
    path: Router.BankAccount.Facilities.BankingActivity.PrimaryPurpose,
    text: 'Banking Activity',
    childPaths: _.map(
      Router.BankAccount.Facilities.BankingActivity,
      (path) => path,
    ),
  },
  {
    id: BUSINESS_ACTIVITY_SECTION_ID,
    path: Router.BankAccount.Facilities.BusinessActivity.ATMLocation,
    text: 'Business Activity',
    childPaths: _.map(
      Router.BankAccount.Facilities.BusinessActivity,
      (path) => path,
    ),
  },
  {
    id: LOCATION_DOCS_SECTION_ID,
    path: Router.BankAccount.Facilities.Documents.UploadDocuments,
    text: 'Documents',
  },
  {
    id: REVIEW_SIGN_SECTION_ID,
    path: Router.BankAccount.Facilities.LocationReview.ActivityTierOne,
    text: 'Review & Sign',
  },
];

const useOnboardingLocationSections = (business?: BusinessDetails) => {
  const [normalizedFacilityMenuItems, setNormalizedFacilityMenuItems] =
    useState<NavigationItem[]>([]);
  const { facilityId, setFacilityCompleted } = useFacilitySectionData();
  const path = useLocation();

  const { data: facilityData, refetch: refetchFacilityData } =
    useGetFacilityById(business?.id ?? '', facilityId ?? '', {
      enabled: false,
    });

  useEffect(() => {
    if (business?.id && facilityId && business?.finishedOnboard) {
      refetchFacilityData().catch(console.error);
    }
  }, [
    path.pathname,
    refetchFacilityData,
    facilityId,
    business?.finishedOnboard,
    business?.id,
  ]);

  const getMenuItemsForNewLocation = useCallback(() => {
    return newFacilityItems.map((navigationItem) => {
      return {
        ...navigationItem,
        checkBox: true,
        isDisabled: !(
          navigationItem.id === 'name-section' &&
          path.pathname === navigationItem.path
        ),
        progressStatus: 'Selected' as BusinessApplicationProgressValue,
      };
    });
  }, [path.pathname]);

  const getMenuItemsForExistingLocation = useCallback(() => {
    const completedSteps = {
      'name-section': !!facilityData?.facilityName,
    };

    _.forEach(facilityData?.facilityInformation, ({ id, pages }) => {
      // if found last page id in facility data, then set the step as completed
      const lastPageId = lastPageMap[id];
      completedSteps[id] = !!_.find(pages, { id: lastPageId });
    });

    return newFacilityItems.map((navigationItem, idx) => {
      const previousItemId = newFacilityItems[idx - 1]?.id as string;
      const isPreviousCompleted = idx === 0 || completedSteps[previousItemId];

      const currentStepCompleted = completedSteps[navigationItem.id];
      return {
        ...navigationItem,
        path:
          currentStepCompleted && completedPathMap[navigationItem.id]
            ? completedPathMap[navigationItem.id]
            : navigationItem.path,
        checkBox: true,
        isDisabled: !isPreviousCompleted,
        progressStatus: currentStepCompleted
          ? 'Completed'
          : ('Selected' as BusinessApplicationProgressValue),
      };
    });
  }, [facilityData]);

  useEffect(() => {
    let menuItems: StepNavigationButtonProps[] = [];

    if (!facilityData?.id) {
      menuItems = getMenuItemsForNewLocation();
    } else {
      menuItems = getMenuItemsForExistingLocation();
    }

    setNormalizedFacilityMenuItems(menuItems);
  }, [
    facilityData,
    path.pathname,
    getMenuItemsForNewLocation,
    getMenuItemsForExistingLocation,
  ]);

  useEffect(() => {
    if (!business?.finishedOnboard) {
      return;
    }

    if (facilityData?.id && facilityData?.facilityInformation) {
      const documentPage = _.find(facilityData.facilityInformation, {
        id: LOCATION_DOCS_SECTION_ID,
      });
      if (
        !!_.find(documentPage?.pages, {
          id: lastPageMap[LOCATION_DOCS_SECTION_ID],
        })
      ) {
        setFacilityCompleted(true);
        return;
      }
    }

    setFacilityCompleted(false);
  }, [facilityData, business?.finishedOnboard, setFacilityCompleted]);

  return normalizedFacilityMenuItems;
};

export { useOnboardingLocationSections };
