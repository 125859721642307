import { useState, useCallback } from 'react';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';

import ModalConfirm from '@components/Modal/ModalConfirm';

interface OwnProps {
  oldData: any;
  newData: any;
  ignore?: string;
  onDiscard: () => void;
}

export const isEqualObj = ({
  oldData,
  newData,
  ignore = '',
}: {
  oldData: any;
  newData: any;
  ignore?: string;
}) => {
  const isEqual = ignore
    ? _isEqual(_omit(oldData, ignore), _omit(newData, ignore))
    : _isEqual(oldData, newData);

  return isEqual;
};

export const useDiscardModal = ({
  oldData,
  newData,
  ignore = '',
  onDiscard,
}: OwnProps) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const isEqual = isEqualObj({ oldData, newData, ignore });

  const discardOptions = {
    isOpen: isOpenConfirm,
    onDiscard,
    onClose: () => setIsOpenConfirm(false),
  };

  const handleCancel = useCallback(() => {
    if (isEqual) {
      onDiscard();
    } else {
      setIsOpenConfirm(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEqual]);

  const renderDiscardModal = () => {
    return (
      <>
        {isOpenConfirm && (
          <ModalConfirm
            open={discardOptions.isOpen}
            title="Discard Changes"
            isLoading={false}
            description={
              <span>
                Your changes have not been saved and will be lost. Do you want
                to continue?
              </span>
            }
            maxWidth={'sm'}
            cancelText="No"
            confirmText="Yes"
            headerAlign="left"
            onClose={discardOptions.onClose}
            onConfirm={discardOptions.onDiscard}
          />
        )}
      </>
    );
  };

  return {
    handleCancel,
    discardOptions,
    DiscardPopup: renderDiscardModal,
  };
};
