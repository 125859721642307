/* eslint-disable @typescript-eslint/promise-function-async */

import { useAuth0 } from '@auth0/auth0-react';
import { lazy, Suspense, useState, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import {
  BankAccountRoutes,
  BusinessDetailsRoutes,
  BusinessDocumentsRoutes,
  BusinessOperatorDetailsRoutes,
  BusinessOwnershipRoutes,
  FacilitiesLocationsRoutes,
  ReviewAndSignRoutes,
  Router as RouterConstant,
  PublicRouter as PublicRouterConstant,
} from '@/constants/router';

import HomePage from '@/pages/Home';

import { facilityRoutes } from './facilities-routes';
import { navItems } from './config';

import { useBusinessId } from '@/utils/hooks/useBusinessId';

import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';

import useAuthenticateUser from '@/api/hooks/useAuthenticateUser';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import { SyncteraVerificationStatus } from '@/constants/synctera';
import type { SystemUserAuthResponse } from '@/types/user';
import { BankAccountComponents } from '@components/Router/bank-account-routes';
import {
  SystemUsersStatuses,
  SystemRoleType,
} from '@pages/BankAccount/SystemUsers/__utils__/mock';
import { useBoolean } from 'usehooks-ts';
import FacilitiesLayout from '../Layouts/FacilitiesLayout';
import NotFound from '../NotFound';
import AlertModal from '../UI/AlertModal';
import { analyticsIdentify } from '@/utils/analytics';
import IdleTimer from '@/utils/idleTimer';
import { StorageKeys } from '@/constants/misc';
import { useRoles } from '@/store/useRoles';

// TODO: move this to `routerConfig` file
// TODO: rest of the paths will be progressively updated to not delay integration

const LazyTellUsAboutYourBusiness = lazy(
  () => import('@pages/BusinessDetails/TellUsAboutYourBusiness'),
);

// const LazyEligibility = lazy(() => import('@pages/Eligibility'));
// const LazyEligibilityStatus = lazy(() => import('@pages/EligibilityStatus'));
// const EligibilityLayout = lazy(
//   () => import('@components/Layouts/EligibilityLayout'),
// );

const LazyEmailVerification = lazy(() => import('@pages/EmailVerification'));

const LazyOnboardingLayout = lazy(
  () => import('@components/Layouts/OnboardingLayout'),
);

const EmailVerificationLayout = lazy(
  () => import('@components/Layouts/EmailVerificationLayout'),
);

const LazyReviewDetails = lazy(() => import('@pages/BusinessDetails/Review'));

const LazyBusinessContact = lazy(
  () => import('@pages/BusinessDetails/BusinessContact'),
);

const LazyBusinessAddress = lazy(
  () => import('@pages/BusinessDetails/BusinessAddress'),
);

const LazyIndividualOwnerInformation = lazy(
  () => import('@pages/BusinessOwnership/IndividualOwnerInformation'),
);

const LazyBusinessOwnerInformation = lazy(
  () => import('@pages/BusinessOwnership/BusinessOwnerInformation'),
);

const LazyBusinessOwnerStart = lazy(
  () => import('@pages/BusinessOwnership/Start'),
);

const LazyBusinessOwnerReview = lazy(
  () => import('@pages/BusinessOwnership/Review'),
);

const LazyBusinessDocuments = lazy(
  () => import('@pages/BusinessDocuments/index'),
);

const LazyBankAccountLayout = lazy(
  () => import('@components/Layouts/BankAccountLayout'),
);

const LazyBusinessOperatorDetails = lazy(
  () => import('@pages/BusinessOperatorDetails'),
);

const LazyLicenseRequisite = lazy(
  () => import('@pages/Facilities/LicenseRequisite'),
);

const LazyLicensedFacilities = lazy(
  () => import('@pages/Facilities/LicensedFacilities'),
);

const LazyLegalNames = lazy(() => import('@pages/Facilities/LegalNames'));

const LazyAddNewFacility = lazy(
  () => import('@pages/Facilities/AddNewFacility'),
);

const LazyLocationRequiredDocs = lazy(
  () => import('@pages/Facilities/Sections/LocationDocs/RequiredDocuments'),
);

const LazySubmitPage = lazy(() => import('@pages/Review&Sign/SubmitPage'));
const LazySubmitSuccessPage = lazy(
  () => import('@pages/Review&Sign/SuccessPage'),
);

const LazyRequiredDocuments = lazy(
  () => import('@pages/BusinessDetails/RequiredDocuments'),
);

const LazyPublicPaymentLayout = lazy(
  () => import('@components/Layouts/PublicPaymentLayout'),
);

const LazyPublicPayment = lazy(() => import('@pages/PublicPayment'));
const LazyPublicExpiredPassword = lazy(
  () => import('@pages/PublicExpiredPassword'),
);
const LadyPublicExistedAccount = lazy(
  () => import('@pages/PublicExistedAccount'),
);
const LazyPublicEmailVerification = lazy(
  () => import('@pages/PublicEmailVerification'),
);

const LazyPublicPlaidAuth = lazy(() => import('@pages/PublicPlaidAuth'));

const LazySystemRolesDetail = lazy(
  async () => await import('@pages/BankAccount/SystemRoles/RoleDetail'),
);

export const PublicRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={PublicRouterConstant.Public}
        element={<LazyPublicPaymentLayout />}
      >
        <Route index element={<LazyPublicPayment />} />
      </Route>
      <Route
        path={PublicRouterConstant.ExpiredPassword}
        element={<LazyPublicPaymentLayout />}
      >
        <Route index element={<LazyPublicExpiredPassword />} />
      </Route>
      <Route
        path={PublicRouterConstant.EmailVerification}
        element={<LazyPublicPaymentLayout />}
      >
        <Route index element={<LazyPublicEmailVerification />} />
      </Route>

      <Route
        path={PublicRouterConstant.ExistedAccount}
        element={<LazyPublicPaymentLayout />}
      >
        <Route index element={<LadyPublicExistedAccount />} />
      </Route>
      <Route
        path={PublicRouterConstant.PlaidAuth}
        element={<LazyPublicPaymentLayout />}
      >
        <Route index element={<LazyPublicPlaidAuth />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function Router(): JSX.Element {
  const { value: isAuthorized, setFalse: setIsAuthorizedFalse } =
    useBoolean(true);

  const {
    value: isSyncteraKycNotAccepted,
    setTrue: setIsSyncteraKycNotAccepted,
  } = useBoolean(false);

  const [systemUser, setSystemUser] = useState<SystemUserAuthResponse>();

  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, isLoading, error, logout, user, loginWithRedirect } =
    useAuth0();

  const isUserAuthed = isAuthenticated && !isLoading;

  const businessId = useBusinessId(isUserAuthed);

  const [showAccountHeader, setShowHeader] = useState(false);

  const { data: businessValidation, refetch } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: authenticatedUser } = useAuthenticateUser({
    enabled: isUserAuthed,
    isEmailVerified: user?.email_verified,
    onSuccess(data) {
      setSystemUser(data.SystemUser);
    },
  });
  const roles = useRoles((state) => state.roles);

  useEffect(() => {
    setSystemUser((prev) => {
      if (!!prev) {
        return {
          ...prev,
          businessId: businessId || undefined,
        };
      }
      return prev;
    });
  }, [businessId]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const timer = new IdleTimer({
      timeout: 60 * 15,
      onTimeout: () => {
        localStorage.removeItem(StorageKeys.Token);
        localStorage.removeItem('facilities-storage');
        localStorage.removeItem('create-new-facility');
        localStorage.removeItem('selected-facility');
        localStorage.removeItem('onboarding-steps');

        void logout({ logoutParams: { returnTo: window.location.origin } });
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, [isAuthenticated, logout]);

  useEffect(() => {
    if (location.pathname.includes('login')) {
      loginWithRedirect().catch(console.error);
      return;
    }
    if (
      isUserAuthed &&
      location.pathname === '/' &&
      user != null &&
      user.sub !== undefined &&
      user.email !== undefined
    ) {
      analyticsIdentify(user.sub, { userId: user.sub, email: user.email });
    }
    if (isAuthenticated || isLoading) return;

    navigate('/', { replace: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (user != null) {
      if (user.email_verified === false) {
        navigate('/verify-email', { replace: true });
      }

      let isAccountApproved = false;
      if (businessValidation != null) {
        const { status: validationStatus } = businessValidation;
        isAccountApproved = validationStatus === ApplicationStatusEnum.APPROVED;
      }

      if (systemUser?.id) {
        const isBusinessOwner = !!authenticatedUser?.IndividualOwner?.length;
        const isSystemUser = !isBusinessOwner;

        const isBusinessOwnerKycAccepted =
          (isBusinessOwner &&
            authenticatedUser?.kycStatus ===
              SyncteraVerificationStatus.ACCEPTED) ||
          isSystemUser;

        const isSystemUserDeactivated =
          systemUser?.status === SystemUsersStatuses.DEACTIVATED;

        if (isAccountApproved && isSystemUserDeactivated) {
          setIsAuthorizedFalse();
        }

        if (isAccountApproved && !isBusinessOwnerKycAccepted) {
          setIsSyncteraKycNotAccepted();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authenticatedUser?.kycStatus,
    businessValidation,
    isUserAuthed,
    navigate,
    setIsAuthorizedFalse,
    systemUser,
    user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const isVerifyEmailPage = location.pathname.includes('verify-email');
    // const isEligibilityPage = location.pathname.includes('eligibility');
    // const userDoesNotHaveBusiness = authenticatedUser?.businesses?.length === 0;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    // if (!isEligibilityPage && !isVerifyEmailPage && userDoesNotHaveBusiness) {
    //   navigate('/eligibility');
    // }

    if (businessValidation != null) {
      const { status: validationStatus } = businessValidation;

      const isAccountApproved =
        validationStatus === ApplicationStatusEnum.APPROVED;

      const isPendingOnboard = [
        ApplicationStatusEnum.ONBOARDING_NOT_FINISHED,
        ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
      ].includes(validationStatus);

      const shouldLockOnSuccessPage = [
        ApplicationStatusEnum.REVIEW_ON_SYNCTERA,
        ApplicationStatusEnum.DENIED,
      ].includes(validationStatus);

      const isSuccessPage =
        location.pathname === RouterConstant.Onboarding.ReviewAndSign.Success;

      const isBankDetailsPage =
        location.pathname === RouterConstant.BankAccount.BusinessDetails;

      const isDashboard =
        location.pathname === RouterConstant.BankAccount.Dashboard;

      const isSystemUsersPage =
        location.pathname === RouterConstant.BankAccount.SystemUsers;

      const isSystemRolesPage =
        location.pathname === RouterConstant.BankAccount.SystemRoleType;

      const isSystemRolesDetailPage =
        location.pathname === RouterConstant.BankAccount.SystemRolesDetail;

      const isReportsPage =
        location.pathname === RouterConstant.BankAccount.Reports;

      const isPaymentProcessingPage =
        location.pathname ===
        RouterConstant.BankAccount.PaymentSystemEnrollment;

      const bankingRoutes = Object.values(RouterConstant.BankAccount);

      const facilitiesBusinessActivityRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.BusinessActivity,
      );

      const facilitiesBankingActivityRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.BankingActivity,
      );

      const facilitiesDetailsRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.Details,
      );

      const facilitiesDocumentsRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.Documents,
      );

      const facilitiesLocationReviewRoutes = Object.values(
        RouterConstant.BankAccount.Facilities.LocationReview,
      );

      const facilitiesLocationRoutes = Object.values(
        RouterConstant.BankAccount.FacilitiesLocations,
      );

      const reviewAndSignRoutes = Object.values(
        RouterConstant.Onboarding.ReviewAndSign,
      );

      if (
        isAccountApproved &&
        !isVerifyEmailPage &&
        !bankingRoutes.includes(location.pathname) &&
        !facilitiesBankingActivityRoutes.includes(location.pathname) &&
        !facilitiesLocationRoutes.includes(location.pathname) &&
        !facilitiesBusinessActivityRoutes.includes(location.pathname) &&
        !facilitiesDetailsRoutes.includes(location.pathname) &&
        !facilitiesDocumentsRoutes.includes(location.pathname) &&
        !facilitiesLocationReviewRoutes.includes(location.pathname) &&
        !reviewAndSignRoutes.includes(location.pathname)
      ) {
        const navigationPortal = navItems(roles, systemUser);
        const initialPath =
          navigationPortal?.[0]?.path ?? RouterConstant.BankAccount.Dashboard;

        navigate(initialPath);
        return;
      }

      if (isPendingOnboard && bankingRoutes.includes(location.pathname)) {
        navigate(RouterConstant.Onboarding.BusinessDetails.About);
        return;
      }

      if (isSuccessPage) return;

      const isShowHeader = [
        isBankDetailsPage,
        isDashboard,
        isSystemUsersPage,
        isSystemRolesPage,
        isSystemRolesDetailPage,
        isReportsPage,
        isPaymentProcessingPage,
      ].some(Boolean);

      setShowHeader(isShowHeader);

      if (shouldLockOnSuccessPage) {
        navigate(RouterConstant.Onboarding.ReviewAndSign.Success);
      } else {
        refetch().catch(console.error);
      }
    }
  });

  const renderBankAccountComponentsRoutes = (): React.ReactElement => {
    const bankAccountRouteNames = Object.keys(BankAccountComponents) as Array<
      keyof typeof BankAccountComponents
    >;

    const filteredBankAccountRouteNames = (() => {
      if (roles.includes(SystemRoleType.ADMINISTRATOR)) {
        return bankAccountRouteNames;
      }

      if (roles.includes(SystemRoleType.OPERATING)) {
        return bankAccountRouteNames.filter((route) => {
          return (
            route !== 'SystemUsers' &&
            route !== 'BusinessDetails' &&
            route !== 'LocationDetails'
          );
        });
      }

      return bankAccountRouteNames.filter((route) => {
        return route === 'Dashboard' || route === 'Overview';
      });
    })();

    const bankAccountComponentsRoutes = filteredBankAccountRouteNames.map(
      (route) => {
        let prop: SystemUserAuthResponse | undefined;
        const routesData = BankAccountComponents[route];

        if (['SystemUsers', 'Dashboard', 'AuthorizedUsers'].includes(route)) {
          prop = systemUser;
        }

        if (routesData.children) {
          const routes = routesData.children.map((childRoute) => {
            return (
              <Route
                key={childRoute.id}
                path={childRoute.path}
                element={childRoute?.component?.(prop)}
              />
            );
          });

          return (
            <>
              {routes}
              <Route
                key={'BankAccountRoutes.SystemRolesDetail'}
                path={BankAccountRoutes.SystemRolesDetail}
                element={<LazySystemRolesDetail />}
              />
            </>
          );
        }

        return (
          <Route
            key={BankAccountComponents[route].id}
            path={BankAccountComponents[route].path}
            element={BankAccountComponents[route]?.component?.(prop)}
          />
        );
      },
    );

    return <>{bankAccountComponentsRoutes}</>;
  };

  if (isSyncteraKycNotAccepted) {
    return (
      <Suspense>
        <AlertModal
          errorMessage={`Your account has not been KYC verified yet. Please contact your Administrator to update your information.`}
          open={true}
          onClose={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        />
      </Suspense>
    );
  }

  const hasNoPermissionLogin =
    !!error?.message?.includes('blocked') || !isAuthorized;

  return (
    <Suspense>
      {hasNoPermissionLogin ? (
        <AlertModal
          errorMessage={`You do not have permission to access this Dashboard.`}
          open={true}
          onClose={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        />
      ) : (
        <Routes>
          <Route path="/" element={<HomePage />} />

          {isUserAuthed && (
            <>
              {/* Temporarily remove  */}
              {/* <Route path="eligibility" element={<EligibilityLayout />}>
                <Route index element={<LazyEligibility />} />
                <Route path="status" element={<LazyEligibilityStatus />} />
              </Route> */}

              <Route path="verify-email" element={<EmailVerificationLayout />}>
                <Route index element={<LazyEmailVerification />} />
              </Route>

              {facilityRoutes.map((parentFacilityRoute) => {
                const {
                  childRoutes: childFacilityRoutes,
                  component: ParentFacilityComponent,
                  id: parentId,
                  path: parentPath,
                } = parentFacilityRoute;

                return (
                  <Route
                    element={ParentFacilityComponent}
                    key={parentId}
                    path={parentPath}
                  >
                    {childFacilityRoutes.map((childFacilityRoute) => {
                      const {
                        component: ChildFacilityComponent,
                        id: childId,
                        path: childPath,
                      } = childFacilityRoute;

                      return (
                        <Route
                          key={childId}
                          path={childPath}
                          element={ChildFacilityComponent}
                        />
                      );
                    })}
                  </Route>
                );
              })}

              <Route
                path={FacilitiesLocationsRoutes.__BasePath__}
                element={
                  systemUser !== undefined ? (
                    <FacilitiesLayout />
                  ) : (
                    <LazyOnboardingLayout />
                  )
                }
              >
                <Route
                  path={FacilitiesLocationsRoutes.LicenseRequisite}
                  element={<LazyLicenseRequisite />}
                />
                <Route
                  path={FacilitiesLocationsRoutes.LicensedFacilities}
                  element={<LazyLicensedFacilities />}
                />
                <Route
                  path={FacilitiesLocationsRoutes.LegalNames}
                  element={<LazyLegalNames />}
                />
                <Route
                  path={FacilitiesLocationsRoutes.AddNewFacility}
                  element={<LazyAddNewFacility />}
                />
                {/* <Route
                  path={FacilitiesLocationsRoutes.NamesAreCorrect}
                  element={<LazyNamesAreCorrect />}
                /> */}
                {/* <Route
                  path={FacilitiesLocationsRoutes.ReviewDetails}
                  element={<LazyLocationReviewDetails />}
                /> */}
                <Route
                  path={FacilitiesLocationsRoutes.RequiredDocs}
                  element={<LazyLocationRequiredDocs />}
                />
              </Route>

              <Route
                path={BusinessDetailsRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessDetailsRoutes.RequiredDocuments}
                  element={<LazyRequiredDocuments />}
                />
                <Route
                  path={BusinessDetailsRoutes.About}
                  element={<LazyTellUsAboutYourBusiness />}
                />

                <Route
                  path={BusinessDetailsRoutes.Address}
                  element={<LazyBusinessAddress />}
                />

                <Route
                  path={BusinessDetailsRoutes.Contact}
                  element={<LazyBusinessContact />}
                />

                <Route
                  path={BusinessDetailsRoutes.Review}
                  element={<LazyReviewDetails />}
                />
              </Route>

              <Route
                path={BusinessOwnershipRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessOwnershipRoutes.IndividualOwner}
                  element={<LazyIndividualOwnerInformation />}
                />

                <Route
                  path={BusinessOwnershipRoutes.BusinessOwner}
                  element={<LazyBusinessOwnerInformation />}
                />

                <Route
                  path={BusinessOwnershipRoutes.Start}
                  element={<LazyBusinessOwnerStart />}
                />

                <Route
                  path={BusinessOwnershipRoutes.ReviewOwners}
                  element={<LazyBusinessOwnerReview />}
                />
              </Route>

              <Route
                path={BusinessDocumentsRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessDocumentsRoutes.UploadDocuments}
                  element={<LazyBusinessDocuments />}
                />
              </Route>

              <Route
                path={BusinessOperatorDetailsRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={BusinessOperatorDetailsRoutes.Details}
                  element={<LazyBusinessOperatorDetails />}
                />
              </Route>

              <Route
                path={ReviewAndSignRoutes.__BasePath__}
                element={<LazyOnboardingLayout />}
              >
                <Route
                  path={ReviewAndSignRoutes.Submit}
                  element={<LazySubmitPage />}
                />

                <Route
                  path={ReviewAndSignRoutes.Success}
                  element={<LazySubmitSuccessPage />}
                />
              </Route>
            </>
          )}

          {isUserAuthed && systemUser !== undefined && (
            <>
              <Route
                path={BankAccountRoutes.__BasePath__}
                element={
                  <LazyBankAccountLayout
                    navList={navItems(roles, systemUser)}
                    systemUser={systemUser}
                    showHeader={showAccountHeader}
                  />
                }
              >
                {renderBankAccountComponentsRoutes()}
              </Route>
            </>
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Suspense>
  );
}

export default Router;
