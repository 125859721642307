import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = (): JSX.Element => {
  return (
    <Box
      component="footer"
      sx={{
        gridArea: 'footer',
        width: '100%',
        position: 'relative',
        py: '2rem',
        px: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: {
            xs: '1.2rem',
            md: '1.3rem',
          },
          textAlign: 'center',
        }}
      >
        Paybotic Financial 2024. All Rights Reserved. Paybotic Financial is a
        financial technology company, not a bank. Banking services are provided
        by Regent Bank, Member FDIC. <br />
        FDIC insurance only covers failure of insured depository institutions.
        Certain conditions must be satisfied for pass-through FDIC deposit
        insurance to apply.
      </Typography>
    </Box>
  );
};

export default Footer;
