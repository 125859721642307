import React, { memo } from 'react';
import List from '@mui/material/List';

import FooterItem from './components/FooterItem';
import { footerData } from './data';

const Metadata: React.FC = memo(() => (
  <List
    sx={{
      p: '2rem 0 0 2rem',
    }}
  >
    {footerData.map((footerItem) => (
      <FooterItem key={footerItem.id} {...footerItem} />
    ))}
  </List>
));

if (import.meta.env.DEV) {
  Metadata.displayName = 'Metadata';
}

export default Metadata;
