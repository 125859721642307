import fetcher from '@/utils/fetcher';

import type {
  UpdateFacilityInformationResponse,
  UpdateFacilityInformationVariables,
} from './types';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const updateFacilityInformation = ({
  businessId,
  section,
  facilityId,
}: UpdateFacilityInformationVariables): Promise<UpdateFacilityInformationResponse> => {
  // eslint-disable-next-line @typescript-eslint/return-await
  return fetcher<UpdateFacilityInformationResponse>({
    body: JSON.stringify({ ...section }),
    endpoint: `/businesses/${businessId}/facilities/${facilityId}/information`,
    method: 'PATCH',
  });
};

export default updateFacilityInformation;
