import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface DebuggerStore {
  isDebug: boolean;
  setIsDebug: (isDebug: boolean) => void;
}

export const useDebugger = create<DebuggerStore>()(
  devtools(
    persist(
      (set) => ({
        isDebug: false,
        setIsDebug: (isDebug: boolean) =>
          set({
            isDebug,
          }),
      }),
      {
        name: 'debugger',
        getStorage: () => localStorage,
      },
    ),
  ),
);
