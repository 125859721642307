import type { BusinessDocumentType } from '@/types/business';

export type FacilityDocumentType = Extract<
  BusinessDocumentType,
  | 'armored_car_service_contract'
  | 'atm_contract'
  | 'certificate_of_insurance'
  | 'debit_processor_contract'
  | 'marijuana_transport_license'
  | 'property_title'
  | 'state_issued_marijuana_license'
>;

type DocumentKey = 'INSURANCE_CERTIFICATE' | 'MARIJUANA_LICENSE';

type LocationKey =
  | 'ARMORED_CAR'
  | 'ATM_CONTRACT'
  | 'INSURANCE_CERTIFICATE'
  | 'DEBIT_PROCESSOR'
  | 'TRANSPORT_LICENSE'
  | 'PROPERTY_TITLE'
  | 'MARIJUANA_LICENSE';

interface FacilityDocument {
  key: string;
  label: string;
  id: FacilityDocumentType;
  subtitle: string[];
  required?: boolean;
}

export const facilityDocuments: Record<DocumentKey, FacilityDocument> = {
  MARIJUANA_LICENSE: {
    key: 'MARIJUANA_LICENSE',
    label: 'State-Issued Marijuana License',
    id: 'state_issued_marijuana_license',
    subtitle: [],
    required: true,
  },
  INSURANCE_CERTIFICATE: {
    key: 'INSURANCE_CERTIFICATE',
    label: 'Certificate of Insurance',
    id: 'certificate_of_insurance',
    subtitle: [],
    required: false,
  },
};

export const locationDocuments: Record<LocationKey, FacilityDocument> = {
  ARMORED_CAR: {
    key: 'ARMORED_CAR',
    label: 'Armored Car',
    id: 'armored_car_service_contract',
    subtitle: [],
  },
  ATM_CONTRACT: {
    key: 'ATM_CONTRACT',
    label: 'ATM Contract',
    id: 'atm_contract',
    subtitle: [],
  },
  INSURANCE_CERTIFICATE: {
    key: 'INSURANCE_CERTIFICATE',
    label: 'Certificate of Insurance',
    id: 'certificate_of_insurance',
    subtitle: [],
  },
  DEBIT_PROCESSOR: {
    key: 'DEBIT_PROCESSOR',
    label: 'Debit Processor',
    id: 'debit_processor_contract',
    subtitle: [],
  },
  TRANSPORT_LICENSE: {
    key: 'TRANSPORT_LICENSE',
    label: 'Marijuana Transport License',
    id: 'marijuana_transport_license',
    subtitle: [],
  },
  PROPERTY_TITLE: {
    key: 'PROPERTY_TITLE',
    label: 'Property Title',
    id: 'property_title',
    subtitle: [],
  },
  MARIJUANA_LICENSE: {
    key: 'MARIJUANA_LICENSE',
    label: 'State-Issued Marijuana License',
    id: 'state_issued_marijuana_license',
    subtitle: [],
  },
};

export const LOCATION_DOCS_SECTION_ID = 'location-docs-section';
export const LOCATION_DOCS_SECTION_TITLE = 'Location Documents';

export const LOCATION_DOCS_PAGE_ID = 'location-docs-page';
export const LOCATION_DOCS_PAGE_TITLE = 'Location Documents';

export const documentOptions = Object.values(facilityDocuments).map(
  (facilityDocument) => ({
    id: facilityDocument.id,
    name: facilityDocument.label,
    subtitles: facilityDocument.subtitle,
    required: facilityDocument.required,
  }),
);

export const locationOptions = Object.values(locationDocuments).map(
  (facilityDocument) => ({
    id: facilityDocument.id,
    name: facilityDocument.label,
    subtitles: facilityDocument.subtitle,
  }),
);
