import fetcher from '@/utils/fetcher';
import type { GetApplicatonFeedbacks } from './types';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getApplicationFeedbacks = (
  facilityId: string,
): Promise<GetApplicatonFeedbacks[]> =>
  fetcher<GetApplicatonFeedbacks[]>({
    endpoint: `/application/${facilityId}/feedbacks`,
  });

export default getApplicationFeedbacks;
