import type { SectionDto } from '@/api/hooks/useUpdateFacilityInformation/types';

import fetcher from '@/utils/fetcher';

const getBusinessFacilityInformation = async (
  businessId: string,
  facilityId: string,
  sectionId: string,
  pageId: string,
): Promise<SectionDto> =>
  await fetcher<SectionDto>({
    endpoint: `/businesses/${businessId}/facilities/${facilityId}/information/${sectionId}/${pageId}`,
  });
export default getBusinessFacilityInformation;
