import type { PlaidLinkTokenResponse } from '@/types/account';
import { isEmpty } from 'lodash';
import publicFetcher from '@/utils/publicFetcher';

interface RequestLinkToken {
  token: string;
  query?: string;
}

const getBuyerLinkToken = async ({
  token,
  query,
}: RequestLinkToken): Promise<PlaidLinkTokenResponse> => {
  const actualParams = !isEmpty(query) ? `?institutionId=${query ?? ''}` : '';
  return await publicFetcher<PlaidLinkTokenResponse>({
    endpoint: `/buyer/link-tokens${actualParams}`,
    userToken: token,
  });
};
export default getBuyerLinkToken;
