export type ExcludeBasePath<T> = Exclude<T, '__BasePath__'>;

interface RouterRoutes {
  __BasePath__: string;

  [path: string]: string;
}

const getRoute = <RR extends RouterRoutes>(
  routerRoutes: RR,
  ...paths: Array<ExcludeBasePath<keyof RR>>
): string => {
  const basePath = `/${routerRoutes.__BasePath__}`;

  const joinedPaths = paths.reduce((accumulator, path) => {
    const pathToJoin = routerRoutes[path]?.toString();

    if (typeof pathToJoin !== 'string') return accumulator;

    accumulator += `/${pathToJoin}`;

    return accumulator;
  }, '');

  return basePath + joinedPaths;
};

export enum BusinessDetailsRoutes {
  __BasePath__ = 'business-details',
  About = 'about',
  Address = 'address',
  Contact = 'contact',
  Review = 'review',
  RequiredDocuments = 'required-documents',
}

export enum BusinessOwnershipRoutes {
  __BasePath__ = 'ownership',
  IndividualOwner = 'individual-owner',
  BusinessOwner = 'business-owner',
  ReviewOwners = 'review',
  Start = 'start',
}

export enum BusinessDocumentsRoutes {
  __BasePath__ = 'business-documents',
  UploadDocuments = 'upload',
}

export const FacilitiesRoutes = {
  __BasePath__: 'facilities',
  Details: {
    __BasePath__: 'facilities/details',
    Manager: 'manager',
    Protocols: 'protocols',
    SoftwareLicense: 'software-license',
    Transportation: 'transportation',
    Type: 'type',
    Address: 'address',
    Products: 'products',
    Review: 'review',
  },
  BankingActivity: {
    __BasePath__: 'facilities/banking-activity',
    PrimaryPurpose: 'primary-purpose',
    PrimaryBusiness: 'primary-business',
    BankingActivityDetail: 'banking-activity-detail',
    Review: 'review',
  },
  BusinessActivity: {
    __BasePath__: 'facilities/business-activity',
    AcceptDebitCards: 'accept-debit-cards',
    AcceptOrTransmitWireTransfers: 'accept-or-transmit-wire-transfers',
    ArmoredCarServices: 'armored-car-services',
    ATMLocation: 'atm-location',
    Review: 'review',
  },
  LocationDocs: {
    __BasePath__: 'facilities/location-docs',
    Documents: 'upload',
  },
  LocationReview: {
    __BasePath__: 'facilities/location-review',
    ActivityTierOne: 'activity-tier-one',
    ActivityTierTwo: 'activity-tier-two',
  },
};

export enum BusinessOperatorDetailsRoutes {
  __BasePath__ = 'business-operator',
  Details = 'details',
}

export enum ReviewAndSignRoutes {
  __BasePath__ = 'review-and-sign',
  Submit = 'submit',
  Success = 'success',
}

export const BankAccountRoutes = {
  __BasePath__: 'bank-account',
  AuthorizedUsers: 'authorized-users',
  Cardholders: 'authorized-users/cardholders',
  DebitLimits: 'authorized-users/debit-limits',
  SystemUsers: 'system-users',
  SystemRoleType: 'system-roles',
  SystemRolesDetail: 'system-roles/detail',
  Overview: 'overview',
  Dashboard: 'dashboard',
  PaymentsAndPayees: 'payments-and-payees',
  ManageScheduledPayments: 'manage-scheduled-payments',
  PayAch: 'payment-ach',
  PayWire: 'payment-wire',
  Success: 'success',
  SuccessAch: 'success-ach',
  SuccessWire: 'success-wire',
  Review: 'review',
  ReviewAch: 'review-ach',
  ReviewWire: 'review-wire',
  DepositCheck: 'deposit-check',
  CompleteDepositCheck: 'complete-deposit-check',
  BusinessDetails: 'bank-details',
  LocationDetails: 'location-details',
  Reports: 'reports',
  PaymentSystemEnrollment: 'payment-processing',
  RequestCash: 'request-cash',
  RequestCashComplete: 'request-cash-complete',
};

export enum FacilitiesLocationsRoutes {
  __BasePath__ = 'facilities-locations',
  LicenseRequisite = 'license-requisite',
  LicensedFacilities = 'licensed-facilities',
  LegalNames = 'legal-names',
  AddNewFacility = 'add-new-facility',
  NamesAreCorrect = 'names-are-correct',
  ReviewDetails = 'review',
  RequiredDocs = 'required-documents',
}

export const Router = {
  Home: '/',
  BankAccount: {
    Overview: getRoute(BankAccountRoutes, 'Overview'),
    Dashboard: getRoute(BankAccountRoutes, 'Dashboard'),
    PaymentsAndPayees: getRoute(BankAccountRoutes, 'PaymentsAndPayees'),
    ManageScheduledPayments: getRoute(
      BankAccountRoutes,
      'ManageScheduledPayments',
    ),
    PayAch: getRoute(BankAccountRoutes, 'PayAch'),
    PayWire: getRoute(BankAccountRoutes, 'PayWire'),
    AuthorizedUsers: getRoute(BankAccountRoutes, 'AuthorizedUsers'),
    Cardholders: getRoute(BankAccountRoutes, 'Cardholders'),
    DebitLimits: getRoute(BankAccountRoutes, 'DebitLimits'),
    SystemUsers: getRoute(BankAccountRoutes, 'SystemUsers'),
    SystemRoleType: getRoute(BankAccountRoutes, 'SystemRoleType'),
    SystemRolesDetail: getRoute(BankAccountRoutes, 'SystemRolesDetail'),
    Success: getRoute(BankAccountRoutes, 'Success'),
    SuccessAch: getRoute(BankAccountRoutes, 'SuccessAch'),
    SuccessWire: getRoute(BankAccountRoutes, 'SuccessWire'),
    Review: getRoute(BankAccountRoutes, 'Review'),
    ReviewAch: getRoute(BankAccountRoutes, 'ReviewAch'),
    ReviewWire: getRoute(BankAccountRoutes, 'ReviewWire'),
    DepositCheck: getRoute(BankAccountRoutes, 'DepositCheck'),
    CompleteDepositCheck: getRoute(BankAccountRoutes, 'CompleteDepositCheck'),
    BusinessDetails: getRoute(BankAccountRoutes, 'BusinessDetails'),
    LocationDetails: getRoute(BankAccountRoutes, 'LocationDetails'),
    Reports: getRoute(BankAccountRoutes, 'Reports'),
    PaymentSystemEnrollment: getRoute(
      BankAccountRoutes,
      'PaymentSystemEnrollment',
    ),
    RequestCash: getRoute(BankAccountRoutes, 'RequestCash'),
    RequestCashComplete: getRoute(BankAccountRoutes, 'RequestCashComplete'),
    FacilitiesLocations: {
      // c
      LegalNames: getRoute(FacilitiesLocationsRoutes, 'LegalNames'),
      // c
      AddNewFacility: getRoute(FacilitiesLocationsRoutes, 'AddNewFacility'),
      LicenseRequisite: getRoute(FacilitiesLocationsRoutes, 'LicenseRequisite'),
      LicensedFacilities: getRoute(
        FacilitiesLocationsRoutes,
        'LicensedFacilities',
      ),
      NamesAreCorrect: getRoute(FacilitiesLocationsRoutes, 'NamesAreCorrect'),
      ReviewDetails: getRoute(FacilitiesLocationsRoutes, 'ReviewDetails'),
      RequiredDocuments: getRoute(FacilitiesLocationsRoutes, 'RequiredDocs'),
    },
    Facilities: {
      Details: {
        Products: getRoute(FacilitiesRoutes.Details, 'Products'),
        Manager: getRoute(FacilitiesRoutes.Details, 'Manager'),
        Protocols: getRoute(FacilitiesRoutes.Details, 'Protocols'),
        SoftwareLicense: getRoute(FacilitiesRoutes.Details, 'SoftwareLicense'),
        Transportation: getRoute(FacilitiesRoutes.Details, 'Transportation'),
        Type: getRoute(FacilitiesRoutes.Details, 'Type'),
        Address: getRoute(FacilitiesRoutes.Details, 'Address'),
        Review: getRoute(FacilitiesRoutes.Details, 'Review'),
      },
      BusinessActivity: {
        AcceptDebitCards: getRoute(
          FacilitiesRoutes.BusinessActivity,
          'AcceptDebitCards',
        ),
        AcceptOrTransmitWireTransfers: getRoute(
          FacilitiesRoutes.BusinessActivity,
          'AcceptOrTransmitWireTransfers',
        ),
        ArmoredCarServices: getRoute(
          FacilitiesRoutes.BusinessActivity,
          'ArmoredCarServices',
        ),
        ATMLocation: getRoute(FacilitiesRoutes.BusinessActivity, 'ATMLocation'),
        Review: getRoute(FacilitiesRoutes.BusinessActivity, 'Review'),
      },
      BankingActivity: {
        PrimaryPurpose: getRoute(
          FacilitiesRoutes.BankingActivity,
          'PrimaryPurpose',
        ),
        PrimaryBusiness: getRoute(
          FacilitiesRoutes.BankingActivity,
          'PrimaryBusiness',
        ),
        BankingActivityDetail: getRoute(
          FacilitiesRoutes.BankingActivity,
          'BankingActivityDetail',
        ),
        Review: getRoute(FacilitiesRoutes.BankingActivity, 'Review'),
      },
      LocationReview: {
        ActivityTierOne: getRoute(
          FacilitiesRoutes.LocationReview,
          'ActivityTierOne',
        ),
        ActivityTierTwo: getRoute(
          FacilitiesRoutes.LocationReview,
          'ActivityTierTwo',
        ),
      },
      Documents: {
        UploadDocuments: getRoute(FacilitiesRoutes.LocationDocs, 'Documents'),
      },
    },
  },
  Onboarding: {
    // 1
    BusinessDetails: {
      About: getRoute(BusinessDetailsRoutes, 'About'),
      Address: getRoute(BusinessDetailsRoutes, 'Address'),
      Contact: getRoute(BusinessDetailsRoutes, 'Contact'),
      Review: getRoute(BusinessDetailsRoutes, 'Review'),
      RequiredDocuments: getRoute(BusinessDetailsRoutes, 'RequiredDocuments'),
    },
    // 3
    BusinessOwnership: {
      IndividualOwner: getRoute(BusinessOwnershipRoutes, 'IndividualOwner'),
      BusinessOwner: getRoute(BusinessOwnershipRoutes, 'BusinessOwner'),
      ReviewOwners: getRoute(BusinessOwnershipRoutes, 'ReviewOwners'),
      Start: getRoute(BusinessOwnershipRoutes, 'Start'),
    },
    // 6
    BusinessDocuments: {
      Upload: getRoute(BusinessDocumentsRoutes, 'UploadDocuments'),
    },
    // 2
    BusinessOperatorDetails: {
      Details: getRoute(BusinessOperatorDetailsRoutes, 'Details'),
    },
    // 5
    FacilitiesLocations: {
      LegalNames: getRoute(FacilitiesLocationsRoutes, 'LegalNames'),
      // a
      LicenseRequisite: getRoute(FacilitiesLocationsRoutes, 'LicenseRequisite'),
      LicensedFacilities: getRoute(
        FacilitiesLocationsRoutes,
        'LicensedFacilities',
      ),
      NamesAreCorrect: getRoute(FacilitiesLocationsRoutes, 'NamesAreCorrect'),
      ReviewDetails: getRoute(FacilitiesLocationsRoutes, 'ReviewDetails'),
      // b
      RequiredDocuments: getRoute(FacilitiesLocationsRoutes, 'RequiredDocs'),
    },
    // 4
    Facilities: {
      Details: {
        Products: getRoute(FacilitiesRoutes.Details, 'Products'),
        Manager: getRoute(FacilitiesRoutes.Details, 'Manager'),
        Protocols: getRoute(FacilitiesRoutes.Details, 'Protocols'),
        SoftwareLicense: getRoute(FacilitiesRoutes.Details, 'SoftwareLicense'),
        Transportation: getRoute(FacilitiesRoutes.Details, 'Transportation'),
        Type: getRoute(FacilitiesRoutes.Details, 'Type'),
        Address: getRoute(FacilitiesRoutes.Details, 'Address'),
        Review: getRoute(FacilitiesRoutes.Details, 'Review'),
      },
      BusinessActivity: {
        AcceptDebitCards: getRoute(
          FacilitiesRoutes.BusinessActivity,
          'AcceptDebitCards',
        ),
        AcceptOrTransmitWireTransfers: getRoute(
          FacilitiesRoutes.BusinessActivity,
          'AcceptOrTransmitWireTransfers',
        ),
        ArmoredCarServices: getRoute(
          FacilitiesRoutes.BusinessActivity,
          'ArmoredCarServices',
        ),
        ATMLocation: getRoute(FacilitiesRoutes.BusinessActivity, 'ATMLocation'),
        Review: getRoute(FacilitiesRoutes.BusinessActivity, 'Review'),
      },
      BankingActivity: {
        PrimaryPurpose: getRoute(
          FacilitiesRoutes.BankingActivity,
          'PrimaryPurpose',
        ),
        PrimaryBusiness: getRoute(
          FacilitiesRoutes.BankingActivity,
          'PrimaryBusiness',
        ),
        BankingActivityDetail: getRoute(
          FacilitiesRoutes.BankingActivity,
          'BankingActivityDetail',
        ),
        Review: getRoute(FacilitiesRoutes.BankingActivity, 'Review'),
      },
      LocationReview: {
        ActivityTierOne: getRoute(
          FacilitiesRoutes.LocationReview,
          'ActivityTierOne',
        ),
        ActivityTierTwo: getRoute(
          FacilitiesRoutes.LocationReview,
          'ActivityTierTwo',
        ),
      },
      Documents: {
        UploadDocuments: getRoute(FacilitiesRoutes.LocationDocs, 'Documents'),
      },
    },

    // 7
    ReviewAndSign: {
      Submit: getRoute(ReviewAndSignRoutes, 'Submit'),
      Success: getRoute(ReviewAndSignRoutes, 'Success'),
    },
  },
};

export const PublicRouter = {
  Public: '/public',
  ExpiredPassword: '/public/expired-password',
  PlaidAuth: '/public/plaid-auth',
  ExistedAccount: '/public/existed-account',
  EmailVerification: '/public/email-verification',
};
