import { UseQueryResult } from '@tanstack/react-query';
import getFacilities from './getFacilities';
import type { FacilityLocationResponse } from './type';
import { useQueryWithCallbacks } from '@/api/utils/useQueryWithCallbacks';
import type { QueryOptionsRequestCallback } from '@/api/types';

const useGetFacilities = (
  businessId: string,
  showInformation: boolean,
  queryOptions?: QueryOptionsRequestCallback<FacilityLocationResponse[]>,
): UseQueryResult<FacilityLocationResponse[], Error> => {
  return useQueryWithCallbacks<FacilityLocationResponse[], Error>({
    queryKey: ['getFacilities'],
    queryFn: async () => await getFacilities(businessId, showInformation),
    ...queryOptions,
  });
};

export default useGetFacilities;
