export const US_STATES = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
] as const;

export type StatesEmpty = typeof US_STATES[number] | '';

export const CANADA_PROVINCES = [
  { id: 'AB', value: 'AB', label: 'Alberta' },
  { id: 'BC', value: 'BC', label: 'British Columbia' },
  { id: 'MB', value: 'MB', label: 'Manitoba' },
  { id: 'NB', value: 'NB', label: 'New Brunswick' },
  { id: 'NL', value: 'NL', label: 'Newfoundland and Labrador' },
  { id: 'NS', value: 'NS', label: 'Nova Scotia' },
  { id: 'ON', value: 'ON', label: 'Ontario' },
  { id: 'PE', value: 'PE', label: 'Prince Edward Island' },
  { id: 'QC', value: 'QC', label: 'Quebec' },
  { id: 'SK', value: 'SK', label: 'Saskatchewan' },
  { id: 'NT', value: 'NT', label: 'Northwest Territories' },
  { id: 'NU', value: 'NU', label: 'Nunavut' },
  { id: 'YT', value: 'YT', label: 'Yukon' },
];

export type CanadaProvinces =
  | 'AB'
  | 'BC'
  | 'MB'
  | 'NB'
  | 'NL'
  | 'NS'
  | 'ON'
  | 'PE'
  | 'QC'
  | 'SK'
  | 'NT'
  | 'NU'
  | 'YT';

export type TStateOrProvince = typeof US_STATES[number] | CanadaProvinces | '';
