import { isEmpty } from 'lodash';
import {
  useContext,
  useMemo,
  useReducer,
  Dispatch,
  createContext,
  useCallback,
} from 'react';

interface LinkProviderProps {
  children: React.ReactNode;
}

interface LinkState {
  currentToken: string;
}

const initialState = {
  currentToken: '',
};

type LinkAction =
  | {
      type: 'LINK_TOKEN_CREATED';
      token: string;
    }
  | { type: 'DELETE_USER_LINK_TOKEN'; id: string };

interface LinkContextShape {
  dispatch: Dispatch<LinkAction>;
  setToken: (token: string) => void;
  currentToken: string;
}
const UserAuthContext = createContext<LinkContextShape>(
  initialState as LinkContextShape,
);

export const UserAuthProvider = (props: LinkProviderProps): JSX.Element => {
  const [{ currentToken }, dispatch] = useReducer(reducer, initialState);

  const setToken = useCallback((token: string) => {
    if (token) {
      dispatch({ type: 'LINK_TOKEN_CREATED', token });
    } else {
      console.log('error');
    }
  }, []);

  const value = useMemo(
    () => ({
      currentToken,
      dispatch,
      setToken,
    }),
    [currentToken, setToken],
  );
  return <UserAuthContext.Provider value={value} {...(props as object)} />;
};

const reducer = (state: LinkState, action: LinkAction): LinkState => {
  switch (action.type) {
    case 'LINK_TOKEN_CREATED':
      return {
        ...state,
        currentToken: action.token,
      };
    case 'DELETE_USER_LINK_TOKEN':
      return {
        ...state,
        currentToken: '',
      };
    default:
      console.warn('unknown action');
      return state;
  }
};

export const useToken = (): LinkContextShape => {
  const context = useContext<LinkContextShape>(UserAuthContext);
  if (isEmpty(context)) {
    throw new Error(`useToken must be used within a UserAuthProvider`);
  }

  return context;
};
export default useToken;
