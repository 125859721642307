import OutlinedInput, {
  outlinedInputClasses,
  OutlinedInputProps,
} from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { CSS_VARS } from '@/constants/css-vars';

interface CustomOutlinedInputProps extends OutlinedInputProps {
  isFilled?: boolean;
}

interface StyledWrapInputProps {
  width?: number;
}

export const StyledInputText = styled(OutlinedInput)<CustomOutlinedInputProps>`
  & .${outlinedInputClasses.notchedOutline} {
    border-color: var(${CSS_VARS.Colors.Gray[200]});
    border-radius: ${(props) => (props.isFilled ? 0 : '0.4rem')};
    color: var(${CSS_VARS.Colors.BlackBlue});
    border-style: ${(props) =>
      props.isFilled
        ? props.readOnly
          ? 'none'
          : 'none none solid none'
        : 'solid'};

    &[aria-disabled='true'] {
      background-color: #e4efff;
      border-color: #e4e4e4;
      color: var(${CSS_VARS.Colors.BrandBlue[400]});
    }

    &[aria-readonly='true'] {
      color: var(${CSS_VARS.Colors.BrandBlue[400]});
      opacity: 0.4;
    }

    & > input {
      border-color: inherit;
    }
  }

  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline} {
    border-color: ${(props) =>
      !props.error && `var(${CSS_VARS.Colors.BrandBlue[300]})`};
    caret-color: ${(props) =>
      !props.error && `var(${CSS_VARS.Colors.BrandBlue[300]})`};
    outline: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & > input {
    padding: ${(props) => (props.isFilled ? '0 1px !important' : '')};
  }
`;

export const inputStyles: React.CSSProperties = {
  borderRadius: 'inherit',
  borderWidth: '0.1rem',
  padding: '1.2rem 1rem',
};

export const StyledWrapInput = styled('div')<StyledWrapInputProps>`
  width: ${({ width }) =>
    Number.isFinite(width) ? `${width as number}px` : ''};
`;
