import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getFacilityById from './getFacilityById';
import type { FacilityResponse } from './type';
import { QueryOptionsRequest } from '@/api/types';

const useGetFacilityById = (
  businessId: string,
  facilityId: string,
  queryOptions?: QueryOptionsRequest<FacilityResponse>,
): UseQueryResult<FacilityResponse, Error> => {
  return useQuery<FacilityResponse, Error>({
    queryKey: [`getFacilityById/${facilityId}`],
    queryFn: async () => await getFacilityById(businessId, facilityId),
    ...queryOptions,
  });
};

export default useGetFacilityById;
