import { StorageKeys } from '@/constants/misc';
import { useAuth0 } from '@auth0/auth0-react';

interface IUseLogout {
  logout: (url?: string) => void;
}

const useLogout = (): IUseLogout => {
  const { logout: logoutAuth0 } = useAuth0();

  const logout = (url?: string): void => {
    localStorage.removeItem(StorageKeys.Token);
    localStorage.removeItem('facilities-storage');
    localStorage.removeItem('create-new-facility');
    localStorage.removeItem('selected-facility');
    localStorage.removeItem('onboarding-steps');

    void logoutAuth0({
      logoutParams: {
        returnTo: `${window.location.origin}${url ?? ''}`,
        federated: true,
      },
    });
  };

  return { logout };
};

export default useLogout;
