import { createTheme } from '@mui/material/styles';

import GoghBold from '@/assets/fonts/gogh-bold.woff2';
import GoghMedium from '@/assets/fonts/gogh-medium.woff2';
import Gogh from '@/assets/fonts/gogh-regular.woff2';

import { CSS_VARS } from '@/constants/css-vars';

export const theme = createTheme({
  palette: {
    primary: {
      100: '#E7F4F7',
      200: '#37BEDD',
      300: '#007E9E',
      400: '#254D73',
      500: '#254D73',
      600: '#132D46',
    },
    grey: {
      100: '#F1F1F1',
      200: '#D2D2D2',
      300: '#B5B8BD',
      400: '#9A9A9D',
      500: '#61666C',
    },
    error: {
      main: '#FE5471',
    },
    success: {
      main: '#00D082',
    },
  },
  typography: {
    allVariants: {
      color: '#132D46',
    },
    fontFamily:
      'Gogh, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    htmlFontSize: 10,
    h1: {
      fontSize: '2.0rem',
      fontWeight: 700,
      lineHeight: '1.1',
      '@media (min-width:600px)': {
        fontSize: '2.4rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '3.4rem',
      },
    },
    h2: {
      fontSize: '1.9rem',
      fontWeight: 700,
      lineHeight: '1.2',
      '@media (min-width:600px)': {
        fontSize: '2.2rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '3.2rem',
      },
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 500,
      lineHeight: '1.3',
      '@media (min-width:600px)': {
        fontSize: '2.0rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '2.8rem',
      },
    },
    h4: {
      fontSize: '1.7rem',
      fontWeight: 700,
      lineHeight: '1.4',
      '@media (min-width:600px)': {
        fontSize: '1.8rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '2.4rem',
      },
    },
    h5: {
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '1.5',
      '@media (min-width:600px)': {
        fontSize: '1.7rem',
      },
      '@media (min-width:1200px)': {
        fontSize: '1.8rem',
      },
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 700,
      lineHeight: '2.0',
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: '1.5',
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: '1.5',
    },
    button: {
      textTransform: 'none',
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: '1.5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: Gogh;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gogh'), local('Gogh-Regular'), url(${Gogh}) format('woff2');
        }
        @font-face {
          font-family: Gogh;
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('Gogh'), local('Gogh-Medium'), url(${GoghMedium}) format('woff2');
        }
        @font-face {
          font-family: Gogh;
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Gogh'), local('Gogh-Bold'), url(${GoghBold}) format('woff2');
        }
        html {
          ${CSS_VARS.Colors.BlackBlue}: #132D46;
          ${CSS_VARS.Colors.White}: #FFFFFF;
          ${CSS_VARS.Colors.Gray[100]}: #F1F1F1;
          ${CSS_VARS.Colors.Gray[200]}: #D2D2D2;
          ${CSS_VARS.Colors.Gray[300]}: #B5B8BD;
          ${CSS_VARS.Colors.Gray[400]}: #9A9A9D;
          ${CSS_VARS.Colors.Gray[500]}: #61666C;
          ${CSS_VARS.Colors.BrandBlue[100]}: #E7F4F7;
          ${CSS_VARS.Colors.BrandBlue[200]}: #37BEDD;
          ${CSS_VARS.Colors.BrandBlue[300]}: #007E9E;
          ${CSS_VARS.Colors.BrandBlue[400]}: #254D73;
          ${CSS_VARS.Colors.Secondary.Hover}: #D75B1D;
          ${CSS_VARS.Colors.Secondary.Solid}: #F6881F;
          ${CSS_VARS.Colors.Gradient.AquaBlue}: linear-gradient(180deg, #56C3DE 0%, #48688C 100%);
          ${CSS_VARS.Colors.Gradient.DarkBlue}: linear-gradient(180deg, #254D73 0%, #0B3A5C 100%, #0B3A5C 100%);
          ${CSS_VARS.Colors.Gradient.Orange}: linear-gradient(180deg, #F6881F 0%, #C6421B 100%);
          ${CSS_VARS.Colors.Success.Darkest}: #0E6A48;
          ${CSS_VARS.Colors.Success.Dark}: #00D082;
          ${CSS_VARS.Colors.Success.Lightest}: #F0FAF5;
          ${CSS_VARS.Colors.Success.Ligth}: #E1F4EA;
          ${CSS_VARS.Colors.Error.Dark}: #FE5471;
          ${CSS_VARS.Colors.Error.Lightest}: #FFECEF;
          ${CSS_VARS.Colors.Error.Ligth}: #FEC0CB;
          ${CSS_VARS.Colors.Warning.Dark}: #FFCD3C;
          ${CSS_VARS.Colors.Warning.Lightest}: #FFF0C5;
          ${CSS_VARS.Colors.Warning.Ligth}: #FFDC77;          
        }
        .MuiDataGrid-columnHeaderTitle {
          text-transform: uppercase;
          font-weight: 700 !important;
          font-size: 12px;
          color: #9a9a9d;
        }
      `,
    },
  },
});
