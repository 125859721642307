import type { ItemDto } from '@/api/hooks/useUpdateFacilityInformation/types';

export const getFacilityDocumentItem = (
  label: string,
  id: string,
  documentId: string,
): ItemDto => {
  return {
    answer: `${label} Document`,
    id,
    question: `${label} Document`,
    type: 'DOCUMENT',
    document_id: documentId,
    description: `${label} Document`,
  };
};
