import fetcher from '@/utils/fetcher';
import type { FacilityResponse } from './type';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getFacilityById = (
  businessId: string,
  facilityId: string,
): Promise<FacilityResponse> =>
  fetcher<FacilityResponse>({
    endpoint: `/businesses/${businessId}/facilities/${facilityId}`,
  });

export default getFacilityById;
