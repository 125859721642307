export const CSS_VARS = {
  Colors: {
    BlackBlue: '--black-blue',
    White: '--white',
    Gray: {
      100: '--gray-100',
      200: '--gray-200',
      300: '--gray-300',
      400: '--gray-400',
      500: '--gray-500',
    },
    BrandBlue: {
      100: '--brand-blue-100',
      200: '--brand-blue-200',
      300: '--brand-blue-300',
      400: '--brand-blue-400',
    },
    Secondary: {
      Hover: '--secondary-hover',
      Solid: '--secondary-solid',
    },
    Gradient: {
      AquaBlue: '--gradient-aqua-blue',
      DarkBlue: '--gradient-dark-blue',
      Orange: '--gradient-orange',
    },
    Success: {
      Darkest: '--success-darkest',
      Dark: '--success-dark',
      Lightest: '--success-lightest',
      Ligth: '--success-light',
    },
    Error: {
      Dark: '--error-dark',
      Lightest: '--error-lightest',
      Ligth: '--error-light',
    },
    Warning: {
      Dark: '--warning-dark',
      Lightest: '--warning-lightest',
      Ligth: '--warning-light',
    },
  },
} as const;
