import { UseQueryResult } from '@tanstack/react-query';
import type { SectionDto } from '@/api/hooks/useUpdateFacilityInformation/types';
import getBusinessFacilityInformation from './getBusinessFacilityInformation';
import { useQueryWithCallbacks } from '@/api/utils/useQueryWithCallbacks';
import type { QueryOptionsRequestCallback } from '@/api/types';

const useGetBusinessFacilityInformation = (
  businessId: string,
  facilityId: string,
  sectionId: string,
  pageId: string,
  queryOptions?: QueryOptionsRequestCallback<SectionDto>,
): UseQueryResult<SectionDto, Error> => {
  return useQueryWithCallbacks<SectionDto, Error>({
    queryKey: [
      `useGetBusinessFacilityInformation/${facilityId}/${pageId}/${sectionId}`,
    ],
    queryFn: async () =>
      await getBusinessFacilityInformation(
        businessId,
        facilityId,
        sectionId,
        pageId,
      ),
    ...queryOptions,
  });
};

export default useGetBusinessFacilityInformation;
