import ReactGA from 'react-ga4';

export const GA = {
  send: ({
    page,
    title,
    hitType = 'hitType',
  }: {
    page?: string;
    title?: string;
    hitType?: string;
  }) => {
    if (!page || !title) return;

    ReactGA.send({
      hitType,
      page,
      title,
    });
  },
};
