import type { Business } from '@/types/business';
import type { SystemUserAuthResponse, User } from '@/types/user';
import type { IndividualOwner } from '@/types/owner-relationship';

import fetcher from '@/utils/fetcher';

type TAuthenticatedUser = User & { businesses: Business[] } & {
  SystemUser: SystemUserAuthResponse;
} & { IndividualOwner: IndividualOwner[] };

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getAuthenticatedUser = (
  isEmailVerified?: boolean,
): Promise<TAuthenticatedUser> =>
  fetcher<TAuthenticatedUser>({
    endpoint: `/users/authenticate${isEmailVerified ? `?isEmailVerified=true` : ''}`,
  });

export default getAuthenticatedUser;
