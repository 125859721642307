export enum SyncteraApplicationStatus {
  Denied = 'DENIED',
  InProgress = 'IN_PROGRESS',
  ReviewInfoRequested = 'REVIEW_INFO_REQUESTED',
  ReviewOnSynctera = 'REVIEW_ON_SYNCTERA',
  Submitted = 'SUBMITTED',
}

export enum SyncteraBusinessType {
  Corporation = 'CORPORATION',
  LLC = 'LLC',
  NonProfit = 'NON_PROFIT',
  Other = 'OTHER',
  Partnership = 'PARTNERSHIP',
  SCorporation = 'S_CORPORATION',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
}

export const businessTypeList = [
  {
    label: 'Corporation',
    value: SyncteraBusinessType.Corporation,
  },
  {
    label: 'Limited Liability Co.',
    value: SyncteraBusinessType.LLC,
  },
  {
    label: 'Partnership',
    value: SyncteraBusinessType.Partnership,
  },
  {
    label: 'Sole Prop/DBA',
    value: SyncteraBusinessType.SoleProprietorship,
  },
  {
    label: 'Government Agency',
    value: SyncteraBusinessType.NonProfit,
  },
  {
    label: 'Other',
    value: SyncteraBusinessType.Other,
  },
];

export enum SyncteraCardStatus {
  Freezed = 'SUSPENDED',
  Canceled = 'TERMINATED',
  Active = 'ACTIVE',
  Unactivated = 'UNACTIVATED',
}

export enum SyncteraVerificationStatus {
  UNVERIFIED = 'UNVERIFIED',
  PENDING = 'PENDING',
  PROVISIONAL = 'PROVISIONAL',
  ACCEPTED = 'ACCEPTED',
  REVIEW = 'REVIEW',
  VENDOR_ERROR = 'VENDOR_ERROR',
  REJECTED = 'REJECTED',
}

export enum SyncteraBusinessVerificationKYB {
  PENDING = 'PENDING',
  PROVISIONAL = 'PROVISIONAL',
  ACCEPTED = 'ACCEPTED',
  REVIEW = 'REVIEW',
  VENDOR_ERROR = 'VENDOR_ERROR',
  REJECTED = 'REJECTED',
}
