import InputDropdown from '@/components/Forms/InputDropdown';
import { CUSTOM_FILTER_MENU_HEIGHT } from '@/constants/misc';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { UseFormGetValues, UseFormWatch } from 'react-hook-form';
import type { FieldErrors } from 'react-hook-form/dist/types/errors';
import type { UseFormRegister } from 'react-hook-form/dist/types/form';
import { tierTwoOptionList } from '../../../Details/Type/data';
import type { FormSchema, PrimaryBusinessType } from '../types';
import InputMask from '@/components/Forms/InputMask';

interface InputFieldProps {
  business: PrimaryBusinessType;
  volume: PrimaryBusinessType;
  register: UseFormRegister<FormSchema>;
  errors: FieldErrors<FormSchema>;
  getValues: UseFormGetValues<FormSchema>;
  watch: UseFormWatch<FormSchema>;
  setValue: (name: string, value: string) => void;
  required?: boolean;
  disabled?: boolean;
  disabledVolume?: boolean;
  errorFlag?: boolean;
  errorFlagVolume?: boolean;
}

function InputField(props: InputFieldProps): JSX.Element {
  const {
    register,
    watch,
    setValue,
    errors,
    business,
    volume,
    getValues,
    required = false,
    disabled = false,
    disabledVolume = false,
    errorFlag = false,
    errorFlagVolume = false,
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(business, e.target.value);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: matches ? 'row' : 'column',
          gap: '55px',
        }}
      >
        <Grid item>
          <InputDropdown
            {...register(business)}
            value={watch?.(business) ?? ''}
            onChange={onChange as any} // eslint-disable-line
            fieldErrors={errors[business]}
            helperText={errors[business]?.message}
            showHelperText
            width={256}
            required={required}
            label="Business Type"
            disabled={disabled}
            errorFlag={errorFlag}
            name="second-tier"
            options={tierTwoOptionList}
            placeholder="Select"
            noNoneOption
            customMenuHeight={CUSTOM_FILTER_MENU_HEIGHT}
          />
        </Grid>
        <Grid item>
          <InputMask
            {...register(volume)}
            fieldErrors={errors[volume]}
            helperText={errors[volume]?.message}
            showHelperText
            width={350}
            type="money"
            label="Anticipated Monthly Transaction Volume"
            value={getValues(volume)}
            required={required}
            disabled={disabledVolume}
            errorFlag={errorFlagVolume}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default InputField;
