/* eslint-disable @typescript-eslint/promise-function-async */

import { nanoid } from 'nanoid';
import { lazy } from 'react';

import { FacilitiesRoutes } from '@/constants/router';

import BankingActivityDetail from '@/pages/Facilities/Sections/BankingActivity/BankingActivityDetail';
import PrimaryBusiness from '@/pages/Facilities/Sections/BankingActivity/PrimaryBusiness';
import PrimaryPurpose from '@/pages/Facilities/Sections/BankingActivity/PrimaryPurpose';
import Address from '@/pages/Facilities/Sections/Details/Address';
import type { ParentRoute } from './types';

// LAYOUT

const FacilitiesLayout = lazy(
  () => import('@components/Layouts/FacilitiesLayout'),
);

// Facility Details IMPORTS ----------------------------------------------------

const Type = lazy(() => import('@/pages/Facilities/Sections/Details/Type'));

const SoftwareLicense = lazy(
  () => import('@/pages/Facilities/Sections/Details/SoftwareLicense'),
);

const Manager = lazy(
  () => import('@/pages/Facilities/Sections/Details/Manager'),
);

const Protocols = lazy(
  () => import('@/pages/Facilities/Sections/Details/Protocols'),
);

const Products = lazy(
  () => import('@/pages/Facilities/Sections/Details/Products'),
);

const Transportation = lazy(
  () => import('@/pages/Facilities/Sections/Details/Transportation'),
);

// Banking Activity IMPORTS ----------------------------------------------------

const ATMLocation = lazy(
  () => import('@pages/Facilities/Sections/BusinessActivity/ATMLocation'),
);

const ArmoredCarServices = lazy(
  () =>
    import('@pages/Facilities/Sections/BusinessActivity/ArmoredCarServices'),
);

const AcceptDebitCards = lazy(
  () => import('@pages/Facilities/Sections/BusinessActivity/AcceptDebitCards'),
);

const AcceptOrTransmitWireTransfers = lazy(
  () =>
    import(
      '@pages/Facilities/Sections/BusinessActivity/AcceptOrTransmitWireTransfers'
    ),
);

const ReviewFacilityDetailsTierOne = lazy(
  () => import('@/pages/Facilities/Sections/Details/Review'),
);

const ReviewFacilityBusinessActivityTierOne = lazy(
  () => import('@/pages/Facilities/Sections/BusinessActivity/Review'),
);

const ReviewFacilityBankingActivityTierOne = lazy(
  () => import('@/pages/Facilities/Sections/BankingActivity/Review'),
);

const LocationReviewDetailsTierOne = lazy(
  () => import('@/pages/ReviewDetails/TierOne'),
);
const LocationReviewDetailsTierTwo = lazy(
  () => import('@/pages/ReviewDetails/TierTwo'),
);

const LocationDocs = lazy(
  () => import('@pages/Facilities/Sections/LocationDocs'),
);

const detailsRoutes: ParentRoute[] = [
  {
    component: <FacilitiesLayout />,
    id: nanoid(),
    path: FacilitiesRoutes.Details.__BasePath__,
    childRoutes: [
      {
        component: <Type />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Type,
      },
      {
        component: <SoftwareLicense />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.SoftwareLicense,
      },
      {
        component: <Manager />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Manager,
      },
      {
        component: <Protocols />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Protocols,
      },
      {
        component: <Transportation />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Transportation,
      },
      {
        component: <Address />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Address,
      },
      {
        component: <Products />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Products,
      },
      {
        component: <ReviewFacilityDetailsTierOne />,
        id: nanoid(),
        path: FacilitiesRoutes.Details.Review,
      },
    ],
  },
];

const businessActivityRoutes: ParentRoute[] = [
  {
    component: <FacilitiesLayout />,
    id: nanoid(),
    path: FacilitiesRoutes.BusinessActivity.__BasePath__,
    childRoutes: [
      {
        component: <ATMLocation />,
        id: nanoid(),
        path: FacilitiesRoutes.BusinessActivity.ATMLocation,
      },
      {
        component: <ArmoredCarServices />,
        id: nanoid(),
        path: FacilitiesRoutes.BusinessActivity.ArmoredCarServices,
      },
      {
        component: <AcceptDebitCards />,
        id: nanoid(),
        path: FacilitiesRoutes.BusinessActivity.AcceptDebitCards,
      },
      {
        component: <AcceptOrTransmitWireTransfers />,
        id: nanoid(),
        path: FacilitiesRoutes.BusinessActivity.AcceptOrTransmitWireTransfers,
      },
      {
        component: <ReviewFacilityBusinessActivityTierOne />,
        id: nanoid(),
        path: FacilitiesRoutes.BusinessActivity.Review,
      },
    ],
  },
];

const bankingActivityRoutes: ParentRoute[] = [
  {
    component: <FacilitiesLayout />,
    id: nanoid(),
    path: FacilitiesRoutes.BankingActivity.__BasePath__,
    childRoutes: [
      {
        component: <BankingActivityDetail />,
        id: nanoid(),
        path: FacilitiesRoutes.BankingActivity.BankingActivityDetail,
      },
      {
        component: <PrimaryBusiness />,
        id: nanoid(),
        path: FacilitiesRoutes.BankingActivity.PrimaryBusiness,
      },
      {
        component: <PrimaryPurpose />,
        id: nanoid(),
        path: FacilitiesRoutes.BankingActivity.PrimaryPurpose,
      },
      {
        component: <ReviewFacilityBankingActivityTierOne />,
        id: nanoid(),
        path: FacilitiesRoutes.BankingActivity.Review,
      },
    ],
  },
];

const locationDocsRoutes: ParentRoute[] = [
  {
    component: <FacilitiesLayout />,
    id: nanoid(),
    path: FacilitiesRoutes.LocationDocs.__BasePath__,
    childRoutes: [
      {
        component: <LocationDocs />,
        id: nanoid(),
        path: FacilitiesRoutes.LocationDocs.Documents,
      },
    ],
  },
];

const locationReviewRoutes: ParentRoute[] = [
  {
    component: <FacilitiesLayout />,
    id: nanoid(),
    path: FacilitiesRoutes.LocationReview.__BasePath__,
    childRoutes: [
      {
        component: <LocationReviewDetailsTierOne />,
        id: nanoid(),
        path: FacilitiesRoutes.LocationReview.ActivityTierOne,
      },
      {
        component: <LocationReviewDetailsTierTwo />,
        id: nanoid(),
        path: FacilitiesRoutes.LocationReview.ActivityTierTwo,
      },
    ],
  },
];

export const facilityRoutes: ParentRoute[] = detailsRoutes.concat(
  businessActivityRoutes,
  bankingActivityRoutes,
  locationReviewRoutes,
  locationDocsRoutes,
);
