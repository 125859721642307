import {
  EINInput,
  MoneyInput,
  NumberInput,
  PercentInput,
  PhoneNumberInput,
  PostalCodeInput,
  SSNInput,
  SINInput,
} from './components';

/** @TODO: Figure out how to couple this with the [`type`](./types.index.tsx) type */
export const maskComponents = {
  number: NumberInput,
  money: MoneyInput,
  phone: PhoneNumberInput,
  postalCode: PostalCodeInput,
  ssn: SSNInput,
  sin: SINInput,
  ein: EINInput,
  percentage: PercentInput,
} as const;
