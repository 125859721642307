import { SEGMENT_WRITE_KEY } from '@/constants/env';

export enum AnalyticsEvent {
  beneficialOwnerBusiness = 'beneficial-owner-business',
  beneficialOwnerIndividual = 'beneficial-owner-individual',
  enteredBusinessDetails = 'entered-business-details',
  startedNewFacility = 'started-new-facility',
  businessOperatorCreated = 'business-operator-created',
  businessManagerCreated = 'business-manager-created',
  cardholderCreated = 'cardholder-created',
  walletOperatorCreated = 'wallet-operator-created',
  applicationStatus = 'application-status',
  applicationSubmitted = 'application-submitted',
}

declare global {
  interface Window {
    analytics?: {
      load: (writeKey: string) => void;
      _writeKey: string;
      page: (pageName?: string) => void;
      track: (name: string, properties: Record<string, unknown>) => void;
      identify: (name: string, properties: Record<string, unknown>) => void;
    };
  }
}

export const analyticsLoad = (): void => {
  if (window.analytics === undefined) return;
  window.analytics._writeKey = SEGMENT_WRITE_KEY;
  window.analytics.load(SEGMENT_WRITE_KEY);
};

export const analyticsPage = (pageName?: string): void => {
  if (window.analytics === undefined) return;
  window.analytics.page(pageName ?? '');
};

export const analyticsTrack = (
  name: string,
  properties?: Record<string, unknown>,
): void => {
  if (window.analytics === undefined) return;
  window.analytics.track(name, properties ?? {});
};

export const analyticsIdentify = (
  userId: string,
  properties?: Record<string, unknown>,
): void => {
  if (window.analytics === undefined) return;
  window.analytics.identify(userId, properties ?? {});
};
