import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type {
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTheme, useMediaQuery } from '@mui/material';

import { CSS_VARS } from '@/constants/css-vars';

import InputMask from '@/components/Forms/InputMask';
import type { AmountsType, FormSchema, TransactionsType } from './types';

interface InputFieldProps {
  amount: AmountsType;
  errors: FieldErrors<FormSchema>;
  register: UseFormRegister<FormSchema>;
  title: string;
  transactions: TransactionsType;
  greyBackground?: boolean;
  getValues: UseFormGetValues<FormSchema>;
  disabled?: boolean;
  disabledTransaction?: boolean;
  errorFlag?: boolean;
  errorFlagTransaction?: boolean;
}

function InputField(props: InputFieldProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    amount,
    errors,
    register,
    title,
    transactions,
    greyBackground = false,
    getValues,
    disabled = false,
    disabledTransaction = false,
    errorFlag = false,
    errorFlagTransaction = false,
  } = props;

  const isGreyBackground = useMemo(
    () => !isMobile && greyBackground,
    [greyBackground, isMobile],
  );
  const anticipateAmountLabel = useMemo(
    () => (isMobile ? 'Anticipated amount per month' : undefined),
    [isMobile],
  );
  const estimateTransactionLabel = useMemo(
    () => (isMobile ? 'Estimated number of transactions per month' : undefined),
    [isMobile],
  );

  return (
    <Grid
      alignItems="flex-start"
      bgcolor={`var(${
        isGreyBackground ? CSS_VARS.Colors.Gray[100] : CSS_VARS.Colors.White
      })`}
      container
      gap={{ xs: '2rem', md: '3rem' }}
      padding="10px"
      flexWrap="nowrap"
      width={{ xs: '100%', md: '764px' }}
      height={{ xs: 'auto', md: '80px' }}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Grid alignItems="center" display="flex">
        <Typography
          color={`var(${CSS_VARS.Colors.BlackBlue})`}
          fontWeight={700}
          fontSize="18px"
          lineHeight="28px"
          width={{ xs: '100%', md: '246px' }}
          alignItems="flex-start"
        >
          {title}
        </Typography>
      </Grid>

      <Grid
        container
        gap="10px"
        padding="0 0.6rem 0 0"
        justifyContent="flex-end"
      >
        <InputMask
          {...register(amount)}
          type="money"
          width={isMobile ? '100%' : '246px'}
          fieldErrors={errors[amount]}
          helperText={errors[amount]?.message}
          showHelperText={errors[amount]?.message != null}
          value={getValues(amount)}
          disabled={disabled}
          errorFlag={errorFlag}
          label={anticipateAmountLabel}
        />

        <InputMask
          {...register(transactions)}
          fieldErrors={errors[transactions]}
          helperText={errors[transactions]?.message}
          showHelperText={errors[amount]?.message != null}
          width={isMobile ? '100%' : '173px'}
          type="number"
          value={getValues(transactions)}
          disabled={disabledTransaction}
          errorFlag={errorFlagTransaction}
          label={estimateTransactionLabel}
        />
      </Grid>
    </Grid>
  );
}

export default InputField;
