import { z } from 'zod';
import { ACCOUNT_TYPES } from '@pages/Facilities/Sections/BankingActivity/PrimaryPurpose/data';

export enum PRIMARY_PURPOSE_PROPERTIES {
  ACCOUNT_TYPE = 'account-type',
}
export const formSchema = z.object({
  [PRIMARY_PURPOSE_PROPERTIES.ACCOUNT_TYPE]: z
    .string()
    .refine(
      (value) => ACCOUNT_TYPES.map(({ id }) => id).includes(value),
      'Primary Purpose is not valid',
    ),
});

export type FormSchema = z.infer<typeof formSchema>;
