import { forwardRef } from 'react';

import { IS_DEV } from '@/constants/env';

import { maskComponents } from './data';
import type { InputMaskProps } from './types';

const InputMask = forwardRef<HTMLInputElement, InputMaskProps>(
  (props, ref): JSX.Element => {
    const { type, ...restOfProps } = props;

    const MaskComponent = maskComponents[type];

    return <MaskComponent {...restOfProps} getInputRef={ref} />;
  },
);

if (IS_DEV) {
  InputMask.displayName = 'InputMask';
}

export default InputMask;
