import { useReducer } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _keyBy from 'lodash/keyBy';

import Footer from '@/components/Footer';
import Header from '@/components/Header';
import SideBarNavigation from '@/components/Sidebar/SideBarNavigation';

import useGetSystemRoles from '@/api/hooks/useGetSystemRoles';
import { TSystemRoles, useSystemRoles } from '@/store/useSystemRoles';

interface WithSidebar {
  hasSidebar: true;
  renderNavList: (toggleMobileSidebarOpen?: () => void) => JSX.Element;
}

interface WithoutSidebar {
  hasSidebar?: never;
}

type BaseLayoutProps = {
  children: React.ReactNode;
  centerContent?: boolean;
  hasHeader?: boolean;
  isBlock?: boolean;
} & (WithSidebar | WithoutSidebar);

const BaseLayout = (props: BaseLayoutProps): JSX.Element => {
  const { children, centerContent, hasHeader, hasSidebar, isBlock } = props;

  const [mobileSidebarOpen, toggleMobileSidebarOpen] = useReducer(
    (s) => !s,
    false,
  );

  useGetSystemRoles({
    enabled: true,
    onSuccess(data) {
      const systemRoles = _keyBy(data, 'type') as TSystemRoles;

      useSystemRoles.setState({ systemRoles });
    },
  });

  const theme = useTheme();
  const isDesktopMode = useMediaQuery(theme.breakpoints.up('md'));

  if (isDesktopMode && mobileSidebarOpen) {
    toggleMobileSidebarOpen();
  }

  let gridAreas = '';

  if (hasHeader) {
    gridAreas += hasSidebar ? "'header header'" : "'header'";
  }

  gridAreas += hasSidebar ? "'sidebar content'" : "'content'";

  gridAreas += hasSidebar ? "'sidebar footer'" : "'footer'";

  const sideBarElement = hasSidebar && (
    <SideBarNavigation
      navComponent={props.renderNavList(
        isDesktopMode ? undefined : toggleMobileSidebarOpen,
      )}
    />
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: {
          xs: "'header' 'content' 'footer'",
          md: gridAreas,
        },
        gridTemplateColumns: {
          xs: 'minmax(0, 1fr)',
          md: hasSidebar ? 'auto 1fr' : '1fr',
        },
        gridTemplateRows: hasHeader ? 'auto 1fr' : '1fr',
        minHeight: '100vh',
      }}
    >
      {hasHeader && (
        <Header
          mobileSidebarOpener={hasSidebar ? toggleMobileSidebarOpen : undefined}
        />
      )}

      {sideBarElement}

      {hasSidebar && (
        <Drawer
          variant="temporary"
          open={mobileSidebarOpen}
          onClose={toggleMobileSidebarOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
            aside: {
              display: {
                xs: 'flex',
                md: 'none',
              },
            },
          }}
        >
          <Box>{sideBarElement}</Box>
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          minWidth: 0,
          gridArea: 'content',
          marginLeft: { xs: 0, md: centerContent ? 'auto' : undefined },
          marginRight: { xs: 0, md: centerContent ? 'auto' : undefined },
          width: { xs: '100%', lg: isBlock ? '100%' : '86rem' },
        }}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  );
};

export default BaseLayout;
