import Box from '@mui/material/Box';
import { memo } from 'react';

import Metadata from './components/Metadata';

interface ISideBarNavigationProps {
  navComponent: JSX.Element;
}

const SideBarNavigation = memo(({ navComponent }: ISideBarNavigationProps) => {
  return (
    <Box
      component="aside"
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        borderRight: '1px solid',
        borderColor: 'grey.100',
        gridArea: 'sidebar',
        minHeight: '100vh',
        padding: '2rem 2rem 2rem 0',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      }}
    >
      {navComponent}
      <Metadata />
    </Box>
  );
});

if (import.meta.env.DEV) {
  SideBarNavigation.displayName = 'SideBarNavigation;';
}

export default SideBarNavigation;
